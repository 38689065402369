import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { OrderForm } from './OrderForm';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../redux/actions/getOrdersAction';
import { FormType, OrderType } from '../constants';
import { OrderData } from '../model';
import {
  convertFrom24To12Format,
  getFormattedDate,
  isPastCutOff,
} from '../helper/date';
import Spinner from '../common/Spinner';
import {
  deleteOrder,
  DELETE_ORDER_SUCCESS,
} from '../redux/actions/deleteOrderAction';
import { showSnackbar } from '../redux/actions/uiActions';
import { parseISO } from 'date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface Props {
  type: string;
  minDate: MaterialUiPickersDate;
  maxDate: MaterialUiPickersDate;
  setOrderCount?: (count: number) => void;
}

export const OrderTable = (props: Props) => {
  const [activeOrder, setActiveOrder] = useState<OrderData | null>(null);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const dispatch = useDispatch();

  const handeClose = () => {
    setOpen(false);
    setConfirm(false);
    setActiveOrder(null);
  };
  const handleOpen = (data: OrderData) => {
    setOpen(true);
    setActiveOrder(data);
  };

  const handleDeleteClicked = (data: OrderData) => {
    setConfirm(true);
    setActiveOrder(data);
  };

  const confirmDelete = async () => {
    setConfirm(false);
    try {
      let result: any = null;
      result = await dispatch(deleteOrder(activeOrder?.orderId));

      if (result?.type === DELETE_ORDER_SUCCESS) {
        dispatch(showSnackbar('Action completed', 'success'));
        dispatch(getOrders());
      } else {
        dispatch(showSnackbar('Action failed', 'error'));
      }
    } catch (e: any) {}
  };

  const fetched = useSelector((state: any) => state.getOrders.fetched);
  const fetching = useSelector(
    (state: any) => state.getOrders?.isFetching || state?.editorder?.isFetching
  );

  useEffect(() => {
    let unmounted = false;
    !unmounted && !fetched && dispatch(getOrders());

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortOrdersByDateDesc = (orders: OrderData): OrderData => {
    let ordersFiltered = orders;

    if (props.minDate && props.maxDate) {
      const minDate = getFormattedDate(new Date(props.minDate));
      ordersFiltered = ordersFiltered.filter((order) => order.date >= minDate);

      const maxDate = getFormattedDate(new Date(props.maxDate));
      ordersFiltered = ordersFiltered.filter((order) => order.date <= maxDate);
    }

    !fetching && props.setOrderCount && props.setOrderCount(ordersFiltered?.length ?? 0);

    return ordersFiltered?.sort((a, b) => {
      const timestamp1 = parseISO(
        `${a.date}T${a.deliveryTime ?? ''}`
      ).getTime();
      const timestamp2 = parseISO(
        `${b.date}T${b.deliveryTime ?? ''}`
      ).getTime();
      return timestamp2 - timestamp1;
    });
  };

  const orders = useSelector((state: any) => {
    if (props.type === OrderType.CURRENT) {
      !fetching && props.setOrderCount && props.setOrderCount(state?.getOrders?.currentOrders?.length ?? 0);
      return state?.getOrders?.currentOrders;
    } else if (props.type === OrderType.PREVIOUS) {
      return sortOrdersByDateDesc(state?.getOrders?.previousOrders);
    } else {
      return sortOrdersByDateDesc(state?.getOrders?.futureOrders);
    }
  });

  const allowEdit = (deliveryTime: string) => {
    if (props.type === OrderType.FUTURE) {
      return true;
    } else if (props.type === OrderType.PREVIOUS) {
      return false;
    }

    if (!deliveryTime) {
      return false;
    }
    return !isPastCutOff(deliveryTime.split(':').join(','));
  };

  if (fetching) {
    return <Spinner />;
  }

  return (
    <>
      {open && (
        <OrderForm
          open={open}
          type={FormType.EDIT}
          data={activeOrder}
          handleClose={handeClose}
        />
      )}
      {confirm && (
        <Dialog
          open={confirm}
          onClose={handeClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Are you sure you want to delete this order?'}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handeClose} color="primary">
              NO
            </Button>
            <Button onClick={confirmDelete} color="primary" autoFocus>
              YES
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <TableContainer component={Paper} style={{ marginTop: '2px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Delivery Time</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Province</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Postal Code</TableCell>
              <TableCell>Notes</TableCell>
              {props.type === OrderType.CURRENT && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders &&
              orders.map((row: any) => (
                <TableRow key={row.orderId}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>
                    {convertFrom24To12Format(row.deliveryTime)}
                  </TableCell>
                  <TableCell>{row.line1}</TableCell>
                  <TableCell>{row.city}</TableCell>
                  <TableCell>{row.province}</TableCell>
                  <TableCell>{row.country}</TableCell>
                  <TableCell>{row.postalCode}</TableCell>
                  <TableCell>{row.notes}</TableCell>
                  {allowEdit(row.deliveryTime) && (
                    <TableCell>
                      <IconButton
                        style={{ padding: 0, margin: 0, marginRight: '10px' }}
                        aria-label="edit"
                        onClick={() => handleOpen(row)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        style={{ padding: 0, margin: 0 }}
                        aria-label="delete"
                        onClick={() => {
                          handleDeleteClicked(row);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
