import jwt_decode from 'jwt-decode';

export const checkAuthenticated = () => {
  const idToken = localStorage.getItem('id_token');
  const accessToken = localStorage.getItem('access_token');

  if (!idToken || !accessToken) {
    return false;
  }

  const decodedToken: any = jwt_decode(idToken);
  const currentDate = new Date();

  // JWT exp is in seconds
  if (decodedToken?.exp && decodedToken?.exp * 1000 < currentDate.getTime()) {
    return false;
  }

  return true;
};
