import React, { useState } from 'react';
import { Paper, Grid, TextField, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';
import { resetPassword } from '../redux/actions/authAction';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '../common/LoadingButton';

export const SignInReset = () => {
  const loginError = useSelector((state: any) => state.auth.errorMessage);
  const loading = useSelector((state: any) => state.auth.isFetching);

  const [cred, setCred] = useState({
    email: '',
    oldPass: '',
    newPass: '',
  });

  const [error, setError] = useState({
    newPass: '',
  });

  const dispatch = useDispatch();

  const handleChange = (type: string, event: any) => {
    const value = event?.target?.value;

    if (value && type === 'newPass') {
      if (
        value.length < 8 ||
        !/[A-Z]/.test(value) ||
        !/[a-z]/.test(value) ||
        !/\d/.test(value) ||
        !/\W/.test(value)
      ) {
        setError({
          newPass: 'invalid password',
        });
      } else {
        setError({
          newPass: '',
        });
      }
    }

    setCred({
      ...cred,
      [type]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(resetPassword(cred));
  };

  return (
    <>
      <Grid
        container
        style={{ minHeight: '90vh' }}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <StyledPaper>
          <Container maxWidth="xs">
            <StyledContainer>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              {loginError && (
                <Typography color="error" align="center" component="p">
                  {loginError}
                </Typography>
              )}
              <StyledForm noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={cred.email}
                  onChange={(e) => handleChange('email', e)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="oldPassword"
                  label="Temp Password"
                  type="password"
                  id="oldPass"
                  autoComplete="old-password"
                  value={cred.oldPass}
                  onChange={(e) => handleChange('oldPass', e)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label="New Password"
                  type="password"
                  id="newPass"
                  autoComplete="new-password"
                  helperText="length of 8 characters required, with a minimum of one number, one special character and a lowercase and uppercase letter"
                  value={cred.newPass}
                  error={error.newPass?.length > 0}
                  onChange={(e) => handleChange('newPass', e)}
                />
                <StyledButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={
                    !(cred.email && cred.oldPass && cred.newPass) ||
                    loading ||
                    error.newPass?.length > 0
                  }
                  loading={loading}
                >
                  Update
                </StyledButton>
              </StyledForm>
            </StyledContainer>
          </Container>
        </StyledPaper>
      </Grid>
    </>
  );
};

const StyledForm = styled.form`
  width: 100%;
  margin-top: 8px;
`;

const StyledButton = styled(LoadingButton)`
  margin: 24px 0px 16px;
  height: 40px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledPaper = styled(Paper)`
  padding: 16px;
  max-width: 600px;
  margin-top: 20px;
`;
