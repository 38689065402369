import {
  CREATE_ADMIN_ORDERS_REQUEST,
  CREATE_ADMIN_ORDERS_SUCCESS,
  CREATE_ADMIN_ORDERS_FAILURE,
} from '../actions/createAdminOrdersAction';

function createAdminOrders(
  state = {
    isFetching: false,
  },
  action: any
) {
  switch (action.type) {
    case CREATE_ADMIN_ORDERS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case CREATE_ADMIN_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
      });
    case CREATE_ADMIN_ORDERS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
}

export default createAdminOrders;
