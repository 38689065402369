import { CALL_API } from '../middleware/api';

import { OrderData } from '../../model';

export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

export function createOrder(data: OrderData) {
  return {
    [CALL_API]: {
      endpoint: 'createorder',
      authenticated: true,
      method: 'POST',
      data,
      types: [CREATE_ORDER_REQUEST, CREATE_ORDER_SUCCESS, CREATE_ORDER_FAILURE],
    },
  };
}
