import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  open: boolean;
  errors: Array<any>;
  handleClose: () => void;
}

export const ExcelFileUploadErrorModal = (props: Props) => {
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
        {
          'Please fix the following errors on you excel file and try uploading again'
        }
      </DialogTitle>
      <DialogActions>
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{ minHeight: '200px' }}
          justify="space-evenly"
        >
          <Grid item>
            <Container>
              {props.errors?.map((item) => {
                return (
                  <React.Fragment key={item.row}>
                    <Typography style={{ marginTop: '5px', fontWeight: 600 }}>
                      Row {item.row}:
                    </Typography>
                    {item.errors?.map((error, index) => {
                      return (
                        <Typography key={index}>
                          {'\u00A0'} {'\u00A0'} - {error}
                        </Typography>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </Container>
          </Grid>
          <Grid item style={{ paddingRight: '24px', marginTop: '30px' }}>
            <Button
              onClick={() => props.handleClose()}
              color="primary"
              size="small"
              variant="contained"
              autoFocus
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const Container = styled.div`
  max-height: 300px;
  overflow: scroll;
  justifycontent: center;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
  }
`;
