import { CALL_API } from '../middleware/api';

import { OrderData } from '../../model';

export const CREATE_ORDER_ADMIN_REQUEST = 'CREATE_ORDER_ADMIN_REQUEST';
export const CREATE_ORDER_ADMIN_SUCCESS = 'CREATE_ORDER_ADMIN_SUCCESS';
export const CREATE_ORDER_ADMIN_FAILURE = 'CREATE_ORDER_ADMIN_FAILURE';

export function createOrderAdmin(data: OrderData) {
  return {
    [CALL_API]: {
      endpoint: 'admin/createOrder',
      authenticated: true,
      method: 'POST',
      data,
      types: [CREATE_ORDER_ADMIN_REQUEST, CREATE_ORDER_ADMIN_SUCCESS, CREATE_ORDER_ADMIN_FAILURE],
    },
  };
}
