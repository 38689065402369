import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
} from '../actions/getOrdersAction';

function getOrders(
  state = {
    isFetching: false,
    orders: null,
    fetched: false,
  },
  action: any
) {
  switch (action.type) {
    case GET_ORDERS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        fetched: false,
        orders: null,
      });
    case GET_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        currentOrders: action?.response?.data?.currentOrders,
        futureOrders: action?.response?.data?.futureOrders,
        previousOrders: action?.response?.data?.previousOrders,
        fetched: true,
      });
    case GET_ORDERS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        fetched: false,
      });
    default:
      return state;
  }
}

export default getOrders;
