import React, { useState } from 'react';
import { Paper, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { CustomerInfoForm } from './CustomerInfoForm';
import { CustomerInfoView } from './CustomerInfoView';
import { Props } from './model';
import { useSelector } from 'react-redux';
import Spinner from '../common/Spinner';

export const CustomerInfo = (props: Props) => {
  const [data, setData] = useState({
    showForm: !props?.data,
  });

  const showForm = () => {
    setData({
      showForm: true,
    });
  };

  const fetching = useSelector((state: any) => state.editProfile?.isFetching);

  if (fetching) {
    return <Spinner />;
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '90vh' }}
    >
      <StyledPaper>
        {data.showForm ? (
          <CustomerInfoForm data={props.data} />
        ) : (
          <CustomerInfoView showForm={showForm} data={props.data} />
        )}
      </StyledPaper>
    </Grid>
  );
};

const StyledPaper = styled(Paper)`
  padding: 16px;
  max-width: 600px;
  margin-top: 20px;
  width: 100%;
`;
