import {
    EDIT_PROFILE_REQUEST,
    EDIT_PROFILE_SUCCESS,
    EDIT_PROFILE_FAILURE,
  } from '../actions/editProfileAction';
  
  function editProfile(
    state = {
      isFetching: false
    },
    action: any
  ) {
    switch (action.type) {
      case EDIT_PROFILE_REQUEST:
        return Object.assign({}, state, {
          isFetching: true
        });
      case EDIT_PROFILE_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false
        });
      case EDIT_PROFILE_FAILURE:
        return Object.assign({}, state, {
          isFetching: false
        });
      default:
        return state;
    }
  }
  
  export default editProfile;
  