import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import React from 'react';
interface Props extends ButtonProps {
  loading: boolean;
  children: any;
}

const LoadingButton = (props: Props) => {
  const { loading, children, ...rest } = props;
  return (
    <Button {...rest}>
      {loading ? <CircularProgress size={15} /> : props.children}
    </Button>
  );
};

export default LoadingButton;
