import { CALL_API } from '../middleware/api';

export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';

export function deleteOrder(id: number | undefined) {
  return {
    [CALL_API]: {
      endpoint: `deleteOrder/${id}`,
      authenticated: true,
      method: 'DELETE',
      types: [DELETE_ORDER_REQUEST, DELETE_ORDER_SUCCESS, DELETE_ORDER_FAILURE],
    },
  };
}
