import { CALL_API } from '../middleware/api';

export const GET_CLIENTS_REQUEST = 'GET_CLIENT_REQUEST';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENTS_FAILURE = 'GET_CLIENT_FAILURE';

export function getClients() {
  return {
    [CALL_API]: {
      endpoint: 'getclients',
      authenticated: true,
      method: 'GET',
      types: [GET_CLIENTS_REQUEST, GET_CLIENTS_SUCCESS, GET_CLIENTS_FAILURE],
    },
  };
}
