import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import backgroundImg from './background.jpg';
import logo from './homepageLogo.png';
import AboutUs from './AboutUs/AboutUs';
import styled from 'styled-components';
// import { getOnFleetData, getOnFleetTasks } from './redux/middleware/api';

const Homepage = () => {
  // getOnFleetData();
  // getOnFleetTasks();
  return (
    <>
      <Grid
        container
        style={{
          minHeight: '100vh',
          marginTop: '1px',
          background: 'black',
        }}
        alignItems="center"
        justify="flex-start"
      >
        <StyledImg src={backgroundImg} alt="background" />
        <Grid
          container
          item
          direction="column"
          justify="center"
          alignItems="center"
          style={{ zIndex: 100 }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ height: '120px', width: '400px' }}
          />
          <Typography variant="caption" style={{ color: 'white' }}>
            MORE THAN JUST A DELIVERY.
          </Typography>
        </Grid>
      </Grid>
      <Grid id="aboutus">
        <AboutUs />
      </Grid>
    </>
  );
};

export default Homepage;

const StyledImg = styled.img`
  height: 100vh;
  width: 100vw;
  position: absolute;
  opacity: 0.3;
  z-index: 0;
  top: 65px;
  @media only screen and (max-width: 768px) {
    top: 57px;
  }
`;
