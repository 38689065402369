import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { AllowedTime } from "../model";

export const isPastCutOff = (cutOff?: string, givenDate?: Date) => {
  if (!cutOff) {
    return false;
  }

  const now = new Date();
  const cutOffArray = cutOff?.split(',');
  const hours = cutOffArray
    ? parseInt(cutOffArray?.length > 1 ? cutOffArray[0] : '17')
    : 17;
  const minutes = cutOffArray
    ? parseInt(cutOffArray?.length > 1 ? cutOffArray[1] : '30')
    : 30;

  const closeTime = givenDate ?? new Date();
  closeTime.setHours(hours);
  closeTime.setMinutes(minutes);

  return now.getTime() >= closeTime.getTime();
};

export const formatDate = (date: any) => {
  var d = new Date(date.toLocaleString('en-US').replace(/[^ -~]/g, '')),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const convertFrom24To12Format = (time24) => {
  try {
    if (time24) {
      const [sHours, minutes] = time24
        .match(/([0-9]{1,2}):([0-9]{2})/)
        ?.slice(1);

      const period = +sHours < 12 ? 'AM' : 'PM';
      const hours = +sHours % 12 || 12;

      return `${hours}:${minutes} ${period}`;
    } else {
      return '';
    }
  } catch (e) {
    console.log('converting time error: ', e);
    return '';
  }
};

export function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return year + '-' + month + '-' + day;
}
export const getMinDate = (date: any, dateMap?: AllowedTime, isAdmin?: boolean) => {
  let d = new Date(date);
  let allowedTimes = dateMap?.[d.getDay()];

  if (isAdmin) {
    let adminDate = new Date();
    adminDate.setDate(adminDate.getDate() - 28);

    let month = '' + adminDate.getMonth();
    let day = '' + adminDate.getDate();
    let year = adminDate.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  if (
    isPastCutOff((allowedTimes?.timeSlotA ?? '12:00').split(':').join(',')) &&
    isPastCutOff((allowedTimes?.timeSlotB ?? '').split(':').join(','))
  ) {
    var numberOfDayToAdd = 2;
    d.setDate(d.getDate() + numberOfDayToAdd);
  } else {
    d.setDate(d.getDate() + 1);
  }

  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const getMaxDate = (date: any) => {
  let d = new Date(date);

  var numberOfDayToAdd = 14;
  d.setDate(d.getDate() + numberOfDayToAdd);

  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const getDeliveryTimes = (
  date: MaterialUiPickersDate,
  dateMap: AllowedTime | undefined
) => {
  const allowedTimes = dateMap?.[date?.getDay() ?? ''];

  const times: string[] = [];
  const timeA = allowedTimes?.timeSlotA ?? '12:00';
  const timeB = allowedTimes?.timeSlotB ?? '';

  if (
    timeA.match(/([0-9]{1,2}):([0-9]{2})/)?.slice(1) &&
    !isPastCutOff(timeA.split(':').join(','), date ?? undefined)
  ) {
    times.push(timeA);
  }

  if (
    timeB.match(/([0-9]{1,2}):([0-9]{2})/)?.slice(1) &&
    !isPastCutOff(timeB.split(':').join(','), date ?? undefined)
  ) {
    times.push(timeB);
  }

  return times;
};
