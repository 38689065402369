export const addDashes = (phone: string) => {
  const phoneString = phone.toString();
  if (phoneString.split('-').length <= 1) {
    let phoneFormatted = phoneString.replace(/\D[^.]/g, '');
    return (
      phoneFormatted.slice(0, 3) +
      '-' +
      phoneFormatted.slice(3, 6) +
      '-' +
      phoneFormatted.slice(6)
    );
  } else {
    return phoneString;
  }
};

export const formatPostalCode = (postalCode: string) => {
  return postalCode.toUpperCase().replace(/\W/g, '').replace(/(...)/, '$1 ');
};

export const validateOrderFieldsFromExcel = (order, index) => {
  const errors: Array<string> = [];
  !order[1] && errors.push('First name is missing');
  !order[2] && errors.push('Address is missing');
  !order[3] && errors.push('City is missing');
  !order[4] && errors.push('Postal code is missing');
  !order[5] && errors.push('Phone number is missing');

  validateFields(order, errors);

  if (errors.length > 0) {
    return { row: index + 1, errors };
  } else {
    return null;
  }
};

const validateFields = (order: any, errors: Array<string>) => {
  order[4] &&
    !/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
      order[4]
    ) &&
    errors.push('Invalid postal code');
  order[5] &&
    !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(order[5]) &&
    errors.push('Invalid phone number');
};
