import axios from 'axios';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_RESET_PASSWORD = 'LOGIN_RESET_PASSWORD';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
interface Credentials {
  email: string;
  pass: string;
}

interface ResetCredentials {
  email: string;
  oldPass: string;
  newPass: string;
}

function requestLogin(creds: Credentials) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds,
  };
}

function receiveLogin(user: any) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    id_token: user.id_token,
  };
}

function loginError(message: string) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
  };
}

function loginResetPassword() {
  return {
    type: LOGIN_RESET_PASSWORD,
    isFetching: false,
    isAuthenticated: false,
  };
}

export function loginUser(creds: Credentials) {
  return (dispatch: any) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(creds));

    return axios
      .post(
        `https://h56d7azi1b.execute-api.ca-central-1.amazonaws.com/prod/login`,
        {
          email: creds.email,
          password: creds.pass,
        }
      )
      .then((response) => {
        localStorage.setItem('id_token', response.data.result.idToken.jwtToken);
        localStorage.setItem(
          'access_token',
          response.data.result.accessToken.jwtToken
        );
        // Dispatch the success action
        dispatch(receiveLogin(response));
      })
      .catch((err) => {
        if (err.response?.data?.message === 'password reset required') {
          dispatch(loginResetPassword());
        } else {
          dispatch(loginError(err.response?.data?.message));
        }
      });
  };
}

export function resetPassword(creds: ResetCredentials) {
  const newCreds: Credentials = {
    email: creds.email,
    pass: creds.newPass,
  };
  return (dispatch: any) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(newCreds));

    return axios
      .post(
        `https://h56d7azi1b.execute-api.ca-central-1.amazonaws.com/prod/reset`,
        {
          email: creds.email,
          password: creds.oldPass,
          newPassword: creds.newPass,
        }
      )
      .then((response) => {
        localStorage.setItem('id_token', response.data.result.idToken.jwtToken);
        localStorage.setItem(
          'access_token',
          response.data.result.accessToken.jwtToken
        );
        // Dispatch the success action
        dispatch(receiveLogin(response));
      })
      .catch((err) => {
        if (err.response?.data?.message === 'password reset required') {
          dispatch(loginResetPassword());
        } else {
          dispatch(loginError(err.response?.data?.message));
        }
      });
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true,
  };
}

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
}

export function logoutUser() {
  return (dispatch: any) => {
    dispatch(requestLogout());
    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    dispatch(receiveLogout());
  };
}
