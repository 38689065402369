import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import { Box, Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { CellParams, DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { getAdminOrderInfo } from '../../redux/actions/getAdminOrderInfo';
import Spinner from '../../common/Spinner';
import { Download } from './Download';
import { OrderData } from '../../model';
import { OrderForm } from '../../Order/OrderForm';
import { FormCreateLoction, FormType, OrderType } from '../../constants';
import ClientListAdmin from './ClientListAdmin';
import { convertFrom24To12Format, getFormattedDate } from '../../helper/date';
import {
  deleteOrderAdmin,
  DELETE_ORDER_ADMIN_SUCCESS,
} from '../../redux/actions/deleteOrderAdminAction';
import { showSnackbar } from '../../redux/actions/uiActions';
import DateRangePicker from '../../common/DateRangePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import UploadOrdersFromExcel from '../../common/UploadOrdersFromExcel';
import GetHistory from '../../History/history';

interface ParamTypes {
  email: string;
}

const filterOrders = (
  orders: any[],
  minDateVal: MaterialUiPickersDate,
  maxDateVal: MaterialUiPickersDate
) => {
  let filteredOrders = orders ?? [];

  if (minDateVal && maxDateVal) {
    const minDate = getFormattedDate(new Date(minDateVal));
    filteredOrders = filteredOrders.filter((order) => order.date >= minDate);
    const maxDate = getFormattedDate(new Date(maxDateVal));
    filteredOrders = filteredOrders.filter((order) => order.date <= maxDate);
  }

  return filteredOrders;
};

const CustomerOrders = () => {
  const selectedRows: any = {};
  const [activeOrder, setActiveOrder] = useState<OrderData | null>(null);
  const [minDateFilter, setMinDateFilter] =
    useState<MaterialUiPickersDate>(null);
  const [maxDateFilter, setMaxDateFilter] =
    useState<MaterialUiPickersDate>(null);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [formType, setFormType] = useState(0);
  const [data, setData] = useState({
    value: 2,
    selectedData: [],
  });

  const fetching = useSelector(
    (state: any) => state.getAdminOrderInfo?.isFetching
  );

  const dispatch = useDispatch();
  const profile = useSelector((state: any) => state.getAdminOrderInfo?.profile);
  const { email } = useParams<ParamTypes>();
  const todayIndex = new Date().getDay();
  const allowedTime = useSelector(
    (state: any) => state?.getAdminOrderInfo?.profile?.allowedTime
  );
  const allowedDay = useSelector(
    (state: any) => state?.getAdminOrderInfo?.profile?.allowedDays
  );
  const timeSlot1 = allowedTime
    ? allowedTime[todayIndex]['timeSlotA']
    : '12:00';
  const timeSlot2 = allowedTime ? allowedTime[todayIndex]['timeSlotB'] : null;
  const showTimeTabs = allowedDay?.includes(todayIndex);

  const orders = useSelector((state: any) => {
    if (data.value === 0) {
      return state?.getAdminOrderInfo?.currentOrders?.filter(
        (order) => order.deliveryTime === timeSlot1
      );
    } else if (data.value === 1) {
      return state?.getAdminOrderInfo?.currentOrders?.filter(
        (order) => order.deliveryTime === timeSlot2
      );
    } else if (data.value === 2) {
      return state?.getAdminOrderInfo?.currentOrders;
    } else if (data.value === 3) {
      return state?.getAdminOrderInfo?.futureOrders;
    } else if (data.value === 4) {
      return state?.getAdminOrderInfo?.previousOrders;
    } else {
      return [];
    }
  });

  const handleChange = (_event: any, newValue: number) => {
    setMinDateFilter(null);
    setMaxDateFilter(null);
    setData({
      ...data,
      value: newValue,
      selectedData: [],
    });
  };

  const handleDataSelection = (event: any) => {
    setData({
      ...data,
      selectedData: event?.rowIds?.length
        ? event.rowIds.map((row: any) => selectedRows[row])
        : [],
    });
  };

  const handeClose = () => {
    setOpen(false);
    setConfirm(false);
    setActiveOrder(null);
  };
  const handleOpen = (formType: any, data: any) => {
    setFormType(formType);
    setOpen(true);
    setActiveOrder(data);
  };
  const handleDeleteClicked = (data: any) => {
    setConfirm(true);
    setActiveOrder(data);
  };

  const confirmDelete = async () => {
    setConfirm(false);
    try {
      let result: any = null;
      result = await dispatch(deleteOrderAdmin(activeOrder));

      if (result?.type === DELETE_ORDER_ADMIN_SUCCESS) {
        dispatch(showSnackbar('Action completed', 'success'));
        dispatch(getAdminOrderInfo(email));
      } else {
        dispatch(showSnackbar('Action failed', 'error'));
      }
    } catch (e: any) {}
  };

  useEffect(() => {
    let unmounted = false;

    const fetchMyAPI = async (isUnmounted: boolean) => {
      if (isUnmounted) {
        return;
      }

      const response = (await dispatch(getAdminOrderInfo(email))) as any;
      const allowedDays = response?.response?.data?.profile[0]?.allowedDays;
      const updateTab = allowedDays?.includes(todayIndex);

      if (updateTab) {
        setData({
          ...data,
          value: 0,
          selectedData: [],
        });
      }
    };

    fetchMyAPI(unmounted);

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: any = [
    { field: 'index', headerName: 'Order', flex: 0.4 },
    { field: 'orderId', headerName: 'Order ID', flex: 0.8 },
    { field: 'name', headerName: 'Name', flex: 0.8, cellClassName: 'orders_table_multiline_column' },
    { field: 'phone', headerName: 'Phone', flex: 0.8 },
    { field: 'date', headerName: 'Delivery Date', flex: 0.7 },
    {
      field: 'deliveryTime',
      headerName: 'Delivery Time',
      flex: 0.7,
      renderCell: (params: CellParams) =>
        convertFrom24To12Format(params?.row?.deliveryTime),
    },
    { field: 'line1', headerName: 'Address', flex: 1, cellClassName: 'orders_table_multiline_column' },
    { field: 'city', headerName: 'City', flex: 0.7, cellClassName: 'orders_table_multiline_column' },
    { field: 'province', headerName: 'Province', flex: 0.7 },
    { field: 'country', headerName: 'Country', flex: 0.7 },
    { field: 'postalCode', headerName: 'Postal Code', flex: 0.7 },
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 1,
      cellClassName: 'orders_table_multiline_column',
      renderCell: (params: CellParams) =>
        <div style={{"margin": "auto 0"}}>{params?.row?.notes}</div>,
    }
  ];

  columns.push({
    field: '',
    headerName: '',
    flex: 0.4,
    cellClassName: 'justify_center',
    renderCell: (params: CellParams) => (
      <div>
        <IconButton
          style={{ padding: 0, margin: 0, marginRight: '10px' }}
          aria-label="edit"
          onClick={() => handleOpen(FormType.EDIT, params.row)}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton
          style={{ padding: 0, margin: 0 }}
          aria-label="delete"
          onClick={() => {
            handleDeleteClicked(params.row);
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
    ),
  });

  const rows = orders
    ? filterOrders(orders, minDateFilter, maxDateFilter).map(
        (order: OrderData) => {
          const id = order.orderId || 1;
          const index = order.index + 1;
          selectedRows[id] = order;
          const notes = order.line2
            ? `${order.line2}, ${order.notes}`
            : order.notes;
          return { ...order, id, notes, index };
        }
      )
    : [{ id: 1 }];

  if (fetching) {
    return <Spinner />;
  }

  return (
    <Grid container direction="column" style={{ padding: '15px' }}>
      <Grid item style={{ width: '100%' }}>
        <Typography variant="h5" align="center">
          {profile?.pharmacyName}
        </Typography>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <Download
              data={data.selectedData
                .sort((a: any, b: any) =>
                  a.city.toLowerCase() > b.city.toLowerCase() ? 1 : -1
                )
                .map((order: any) => {
                  const notes = order.line2
                    ? `${profile?.pharmacyName}: ${order.line2}, ${order.notes}`
                    : `${profile?.pharmacyName}: ${order.notes}`;
                  return { ...order, notes, pharmacyName: profile?.pharmacyName };
                })}
              name={profile?.pharmacyName}
            />
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item style={{ marginBottom: '10px', marginRight: '10px' }}>
                <UploadOrdersFromExcel
                  profile={profile}
                  isAdmin={true}
                ></UploadOrdersFromExcel>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginBottom: '10px' }}
                  onClick={(e) => handleOpen(FormType.CREATE, {})}
                >
                  Create Order
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <Box>
          <Paper>
            <Tabs
              value={data.value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
            >
              {showTimeTabs ? (
                <Tab
                  label={`CUTOFF - ${convertFrom24To12Format(timeSlot1)}`}
                  value={0}
                />
              ) : null}
              {showTimeTabs && timeSlot2 ? (
                <Tab
                  label={`CUTOFF - ${convertFrom24To12Format(timeSlot2)}`}
                  value={1}
                />
              ) : null}
              <Tab label="Current Orders" value={2} />
              <Tab label="Future Orders" value={3} />
              <Tab label="Previous Orders" value={4} />
              <Tab label="Clients" value={5} />
              <Tab label="History" value={6} style={{marginLeft: 'auto'}} />
            </Tabs>
          </Paper>
        </Box>
      </Grid>
      {(data.value === 0 || data.value === 1 || data.value === 2) && (
        <Grid item style={{ width: '100%' }}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              style={{
                border: '1px solid black',
                padding: '.5rem',
                margin: '.75rem',
              }}
            >
              {`Order Count: ${ rows.length ?? 0 }`}
            </Grid>
          </Grid>
        </Grid>
      )}
      {(data.value === 3 || data.value === 4) && (
        <Grid item style={{ width: '100%' }}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <DateRangePicker
                minDate={minDateFilter}
                maxDate={maxDateFilter}
                setMinDate={setMinDateFilter}
                setMaxDate={setMaxDateFilter}
                dateRangeType={data.value === 3 ? OrderType.FUTURE : OrderType.PREVIOUS}
              />
            </Grid>
            <Grid
              item
              style={{
                border: '1px solid black',
                padding: '.5rem',
                marginLeft: '.75rem',
              }}
            >
              {`Order Count: ${
                minDateFilter && maxDateFilter ? rows.length : 0
              }`}
            </Grid>
          </Grid>
        </Grid>
      )}
      {(data.value === 0 || data.value === 1 || data.value === 2 || data.value === 3 || data.value === 4) && (
        <Grid item style={{ minHeight: '72vh', width: '100%' }}>
          <DataGrid
            disableColumnMenu
            density="standard"
            rowHeight={100}
            rows={rows}
            columns={columns}
            pageSize={20}
            checkboxSelection
            onSelectionChange={handleDataSelection}
          />
        </Grid>
      )}
      {data.value === 5 && (
        <Grid item>
          <ClientListAdmin email={email} />
        </Grid>
      )}
      {data.value === 6 &&
        <GetHistory email={email} />
      }
      {open && (
        <OrderForm
          open={open}
          type={formType}
          data={activeOrder ? { ...activeOrder, email } : null}
          location={formType ? FormCreateLoction.COMMON : ''}
          handleClose={handeClose}
        />
      )}
      {confirm && (
        <Dialog
          open={confirm}
          onClose={handeClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Are you sure you want to delete this order?'}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handeClose} color="primary">
              NO
            </Button>
            <Button onClick={confirmDelete} color="primary" autoFocus>
              YES
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Grid>
  );
};

export default CustomerOrders;
