import { CALL_API } from '../middleware/api';

export const DELETE_CLIENT_REQUEST = 'DELETE_CLIENT_REQUEST';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILURE = 'DELETE_CLIENT_FAILURE';

export function deleteClient(id: string | undefined) {
  return {
    [CALL_API]: {
      endpoint: `deleteclient/${id}`,
      authenticated: true,
      method: 'DELETE',
      types: [
        DELETE_CLIENT_REQUEST,
        DELETE_CLIENT_SUCCESS,
        DELETE_CLIENT_FAILURE,
      ],
    },
  };
}
