import { CALL_API } from '../middleware/api';

import { OrderData } from '../../model';

export const EDIT_ORDER_REQUEST = 'EDIT_ORDER_REQUEST';
export const EDIT_ORDER_SUCCESS = 'EDIT_ORDER_SUCCESS';
export const EDIT_ORDER_FAILURE = 'EDIT_ORDER_FAILURE';

export function editOrder(updatedData: OrderData, originalData: OrderData | null) {
  return {
    [CALL_API]: {
      endpoint: 'editorder',
      authenticated: true,
      method: 'POST',
      data: {
        originalData,
        updatedData
      },
      types: [EDIT_ORDER_REQUEST, EDIT_ORDER_SUCCESS, EDIT_ORDER_FAILURE],
    },
  };
}
