import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
  component: any;
  isAuthenticated: boolean;
  data?: any
}

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  data,
  ...rest
}: Props) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} data={data} />
        ) : (
          <Redirect
            to={{ pathname: '/signin', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
