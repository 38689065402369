import { CALL_API } from '../middleware/api';

import { ClientData } from '../../model';

export const EDIT_CLIENT_REQUEST = 'EDIT_CLIENT_REQUEST';
export const EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS';
export const EDIT_CLIENT_FAILURE = 'EDIT_CLIENT_FAILURE';

export function editClient(data: ClientData) {
  return {
    [CALL_API]: {
      endpoint: 'editclient',
      authenticated: true,
      method: 'POST',
      data,
      types: [EDIT_CLIENT_REQUEST, EDIT_CLIENT_SUCCESS, EDIT_CLIENT_FAILURE],
    },
  };
}
