export enum FormType {
  EDIT,
  CREATE,
}

export const FormCreateLoction = {
  CLIENTLIST: 'Create Button - Client List',
  COMMON: 'Create Button - Common',
  UPLOAD: 'File Upload'
}

export const OrderType = {
  CURRENT: 'current',
  FUTURE: 'future',
  PREVIOUS: 'previous',
};

export enum Fields {
  Phone = 'phone',
  PostalCode = 'postalCode',
}

export const DayToNameMap = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};
