import { Box, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CustomerList from './admin/customer/CustomerList';
import ClientList from './client/ClientList';
import DateRangePicker from './common/DateRangePicker';
import UploadOrdersFromExcel from './common/UploadOrdersFromExcel';
import { OrderType } from './constants';
import { OrderTable } from './Order/OrderTable';

export const Dashboard = (props: any) => {
  const [data, setData] = useState({
    value: 0,
  });
  const [minDateFilter, setMinDateFilter] =
    useState<MaterialUiPickersDate>(null);
  const [maxDateFilter, setMaxDateFilter] =
    useState<MaterialUiPickersDate>(null);
  const profile = useSelector((state: any) => state?.getProfile?.profile);
  const [count, setCount] = React.useState(0);
  const [showCount, setShowCount] = React.useState(false);

  const handleChange = (_event: any, newValue: number) => {
    setMinDateFilter(null);
    setMaxDateFilter(null);
    setData({
      ...data,
      value: newValue,
    });
  };

  const setOrderCount = (count: number) => {
    setCount(count);
    if (count) {
      setShowCount(true);
    }
  }

  const renderTabView = () => {
    if (data.value === 0) {
      return <ClientList />;
    } else if (data.value === 1) {
      return (
        <>
          <Grid container direction='column' alignItems='center'>
          { showCount && 
              <Grid item
                style={{
                  border: '1px solid black',
                  padding: '.5rem',
                  margin: '.75rem',
                }}
              >
                {`Order Count: ${ count }`}
            </Grid>
          }
            <Grid item style={{width: '100%'}}>  
              <OrderTable setOrderCount={setOrderCount} type={OrderType.CURRENT} minDate={null} maxDate={null} />
            </Grid>
          </Grid>
        </>
      );
    } else if (data.value === 2) {
      return (
        <>
          <DateRangePicker
            minDate={minDateFilter}
            maxDate={maxDateFilter}
            setMinDate={setMinDateFilter}
            setMaxDate={setMaxDateFilter}
            dateRangeType={OrderType.FUTURE}
            showOrderCount={true}
            orderCount={count}
          />{' '}
          <OrderTable
            type={OrderType.FUTURE}
            minDate={minDateFilter}
            maxDate={maxDateFilter}
            setOrderCount={setOrderCount}
          />
        </>
      );
    } else {
      return (
        <>
          <DateRangePicker
            minDate={minDateFilter}
            maxDate={maxDateFilter}
            setMinDate={setMinDateFilter}
            setMaxDate={setMaxDateFilter}
            dateRangeType={OrderType.PREVIOUS}
            showOrderCount={true}
            orderCount={count}
          />{' '}
          <OrderTable
            type={OrderType.PREVIOUS}
            minDate={minDateFilter}
            maxDate={maxDateFilter}
            setOrderCount={setOrderCount}
          />
        </>
      );
    }
  };

  if (props.data) {
    return <CustomerList />;
  }

  return (
    <div
      style={{
        marginTop: '5px',
        minHeight: '90vh',
        padding: '10px 20px',
      }}
    >
      {!props.data && (
        <>
          <Grid container direction="column">
            <Grid
              item
              style={{ marginBottom: '10px', width: '100%', textAlign: 'end' }}
            >
              <UploadOrdersFromExcel profile={profile} isAdmin={false}></UploadOrdersFromExcel>
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Box>
                <Paper>
                  <Tabs
                    value={data.value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                  >
                    <Tab label="Clients" />
                    <Tab label="Current Orders" />
                    <Tab label="Future Orders" />
                    <Tab label="Previous Orders" />
                  </Tabs>
                </Paper>
                {renderTabView()}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
