import { CALL_API } from '../middleware/api';

export const GET_ORDERS_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDER_FAILURE';

export function getOrders() {
  return {
    [CALL_API]: {
      endpoint: 'getorders',
      authenticated: true,
      method: 'GET',
      types: [GET_ORDERS_REQUEST, GET_ORDERS_SUCCESS, GET_ORDERS_FAILURE],
    },
  };
}
