// import Onfleet from '@onfleet/node-onfleet';
import axios from 'axios';

// const BASE_URL = 'http://localhost:4004/';
const BASE_URL ='https://knk6nipgii.execute-api.ca-central-1.amazonaws.com/prod/';

// const onfleetApi = new Onfleet("5b109f7a84e91e6db1bfd905c0ec81de");

// export const getOnFleetData = () => {
//   onfleetApi.workers.get().then(results => console.log(results))
// }

// export const getOnFleetTasks = () => {
//   onfleetApi.tasks.get({from: "1573702616000", to: "1666384557794"}).then(results => console.log(results))
// }

function returnAuthHeader() {
  let config;
  try {
    let authToken = localStorage.getItem('id_token') || null;
    let accessToken = localStorage.getItem('access_token') || null;

    if (authToken && accessToken) {
      config = {
        headers: {
          Authorization: authToken,
          Accesstoken: accessToken,
          Pragma: 'no-cache',
        },
      };
    } else {
      throw new Error('No token saved!');
    }
  } catch (_e) {
    console.log('error retrieving tokens');
  }
  return config;
}

function callApi(endpoint: any, authenticated: boolean, data?: any) {
  let config = authenticated ? returnAuthHeader() : {};
  config = data ? {...config, params: data} : config;
  return axios
    .get(BASE_URL + endpoint, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

function callDeleteApi(endpoint: any, authenticated: boolean) {
  const config = authenticated ? returnAuthHeader() : {};
  return axios
    .delete(BASE_URL + endpoint, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

function callPostApi(endpoint: any, authenticated: boolean, data: any) {
  const config = authenticated ? returnAuthHeader() : {};
  return axios
    .post(BASE_URL + endpoint, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

export const CALL_API = Symbol('Call API');

export default (store: any) => (next: any) => (action: any) => {
  const callAPI = action[CALL_API];

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint, types, authenticated, method, data } = callAPI;

  const [fetchingType, successType, errorType] = types;

  next({
    type: fetchingType,
  });

  // Passing the authenticated boolean back in our data will let us distinguish between normal and secret quotes
  if (method === 'GET') {
    return callApi(endpoint, authenticated, data).then(
      (response) =>
        next({
          response,
          authenticated,
          type: successType,
        }),
      (error) =>
        next({
          error: error.message || 'There was an error.',
          type: errorType,
        })
    );
  } else if (method === 'POST') {
    return callPostApi(endpoint, authenticated, data).then(
      (response) =>
        next({
          response,
          authenticated,
          type: successType,
        }),
      (error) =>
        next({
          error: error.message || 'There was an error.',
          type: errorType,
        })
    );
  } else if (method === 'DELETE') {
    return callDeleteApi(endpoint, authenticated).then(
      (response) =>
        next({
          response,
          authenticated,
          type: successType,
        }),
      (error) =>
        next({
          error: error.message || 'There was an error.',
          type: errorType,
        })
    );
  }
};
