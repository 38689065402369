import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  TextField,
  Typography,
  Button,
  Dialog,
  Box,
  InputLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  createOrder,
  CREATE_ORDER_SUCCESS,
} from '../redux/actions/createOrderAction';
import { getOrders } from '../redux/actions/getOrdersAction';
import { Fields, FormType } from '../constants';
import {
  editOrder,
  EDIT_ORDER_SUCCESS,
} from '../redux/actions/editOrderAction';
import { OrderData } from '../model';
import {
  convertFrom24To12Format,
  formatDate,
  getDeliveryTimes,
  getMaxDate,
  getMinDate,
} from '../helper/date';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { showSnackbar } from '../redux/actions/uiActions';
import {
  editOrderAdmin,
  EDIT_ORDER_ADMIN_SUCCESS,
} from '../redux/actions/editOrderAdminAction';
import { getAdminOrderInfo } from '../redux/actions/getAdminOrderInfo';
import { CREATE_ORDER_ADMIN_SUCCESS, createOrderAdmin } from '../redux/actions/createOrderAdminAction';

interface Props {
  data: OrderData | null;
  type: number;
  open: boolean;
  location?: string;
  handleClose: () => void;
}

export const OrderForm = (props: Props) => {
  const [error, setError] = useState({ phone: '', postalCode: '' });

  const [formIsDirty, setFormIsDirty] = useState(true);

  const isAdmin = useSelector(
    (state: any) => state?.getProfile?.profile?.admin
  );

  let dateMap = useSelector((state: any) => {
    if (isAdmin) {
      return state?.getAdminOrderInfo?.profile?.allowedTime ?? {};
    } else {
      return state?.getProfile?.profile?.allowedTime ?? {};
    }
  });

  let allowedDays = useSelector((state: any) => {
    if (isAdmin) {
      return state?.getAdminOrderInfo?.profile?.allowedDays;
    } else {
      return state?.getProfile?.profile?.allowedDays;
    }
  });

  allowedDays = allowedDays ? allowedDays : Array.from(Array(7).keys());

  const [data, setData] = useState({
    name: props?.data?.name || '',
    phone: props?.data?.phone || '',
    line1: props?.data?.line1 || '',
    line2: props?.data?.line2 || '',
    city: props?.data?.city || '',
    province: props?.data?.province || '',
    postalCode: props?.data?.postalCode || '',
    country: props?.data?.country || '',
    notes: props?.data?.notes || '',
    date: props?.data?.date || null,
    deliveryTime: props?.data?.deliveryTime || '',
  });

  const dispatch = useDispatch();

  function disableDays(date: any) {
    return !allowedDays.includes(date.getDay());
  }

  const validate = (field: string, val: string) => {
    let tempError: any = {};

    if (field === Fields.Phone) {
      const phoneno = /^\d{3}-\d{3}-\d{4}$/;
      if (val && val?.length && !val.match(phoneno)) {
        tempError.phone = 'phone number must match xxx-xxx-xxxx';
      } else {
        tempError.phone = '';
      }
    } else if (field === Fields.PostalCode) {
      const postalCode =
        /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/;
      if (val && val?.length && !val.match(postalCode)) {
        tempError.postalCode = 'please enter a valid postal code';
      } else {
        tempError.postalCode = '';
      }
    }

    setError({ ...error, ...tempError });
  };

  const [selectedDate, handlePickerDateChange] =
    useState<MaterialUiPickersDate>(
      props?.data?.date ? new Date(props.data.date.replace(/-/g, '/')) : null
    );

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (!date) {
      return;
    }

    handlePickerDateChange(date);
    const val: string = formatDate(date);

    setFormIsDirty(false);

    setData({
      ...data,
      date: val,
      deliveryTime: ''
    });
  };

  const handleChange = (type: string, event: any) => {
    let value = event?.target?.value;

    setFormIsDirty(false);

    if (value && type === Fields.PostalCode) {
      value = value.toUpperCase();
    }

    validate(type, value);
    setData({
      ...data,
      [type]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let result: any = null;
    if (props.type === FormType.CREATE) {
      const createMethod = props.location || '';
      if (!isAdmin) {
        result = await dispatch(createOrder({...data, createMethod}));
      } else {
        result = await dispatch(
          createOrderAdmin({ ...data, email: props?.data?.email, createMethod})
        );
      }
    } else {
      if (!isAdmin) {
        result = await dispatch(
          editOrder({
            ...data,
            orderId: props?.data?.orderId,
          }, props.data)
        );
      } else {
        result = await dispatch(
          editOrderAdmin({
            ...data,
            orderId: props?.data?.orderId,
            email: props?.data?.email,
          }, props.data)
        );
      }
    }

    if (
      result?.type === CREATE_ORDER_SUCCESS ||
      result?.type === EDIT_ORDER_SUCCESS ||
      result?.type === CREATE_ORDER_ADMIN_SUCCESS ||
      result?.type === EDIT_ORDER_ADMIN_SUCCESS
    ) {
      dispatch(showSnackbar('Action completed', 'success'));
      if (!isAdmin) {
        dispatch(getOrders());
      } else {
        dispatch(getAdminOrderInfo(props?.data?.email || ''));
      }
    } else {
      dispatch(showSnackbar('Action failed', 'error'));
    }

    props.handleClose();
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Dialog color="white" open={props.open} onClose={props.handleClose}>
        <Box style={{ minWidth: 500, width: '100%' }} padding="20px 40px">
          <Typography align="center" variant="h5">
            {props.type === FormType.CREATE ? 'Create' : 'Edit'} Order
          </Typography>
          <div style={{ minHeight: '5px' }} />
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                name="name"
                type="text"
                label="Name"
                value={data.name}
                onChange={(e) => handleChange('name', e)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                name="phone"
                type="phone"
                label="Phone"
                value={data.phone}
                error={error.phone !== ''}
                helperText={error.phone}
                onChange={(e) => handleChange('phone', e)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name="line1"
                type="text"
                label="Address Line 1"
                value={data.line1}
                onChange={(e) => handleChange('line1', e)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="line2"
                type="text"
                label="Address Details (Unit #, Buzzer)"
                value={data.line2}
                onChange={(e) => handleChange('line2', e)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                name="city"
                type="text"
                label="City"
                value={data.city}
                onChange={(e) => handleChange('city', e)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                name="province"
                type="text"
                label="Province"
                value={data.province}
                disabled={!isAdmin}
                onChange={(e) => handleChange('province', e)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                name="postalCode"
                type="text"
                label="Postal Code"
                value={data.postalCode}
                error={error.postalCode !== ''}
                helperText={error.postalCode}
                onChange={(e) => handleChange('postalCode', e)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                name="country"
                type="text"
                label="Country"
                value={data.country}
                disabled={!isAdmin}
                onChange={(e) => handleChange('country', e)}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  fullWidth
                  label="Delivery Date"
                  helperText="Date you want the order delivered"
                  value={selectedDate}
                  error={!data.date}
                  shouldDisableDate={disableDays}
                  minDate={getMinDate(new Date(), dateMap, isAdmin)}
                  maxDate={getMaxDate(new Date())}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  onChange={handleDateChange}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="column">
                <Grid item>
                  <InputLabel
                    id="deliveryTime-label"
                    required
                    style={{ fontSize: '.75rem' }}
                  >
                    Delivery Time
                  </InputLabel>
                </Grid>
                {selectedDate ? (
                  <>
                    <Grid item>
                      <FormControl component="fieldset">
                        <RadioGroup
                          name="controlled-radio-buttons-group"
                          value={data?.deliveryTime}
                          onChange={(e) => handleChange('deliveryTime', e)}
                        >
                          {getDeliveryTimes(selectedDate, dateMap).map(
                            (item, index) => {
                              return (
                                <FormControlLabel
                                  key={index}
                                  value={item}
                                  control={<Radio />}
                                  label={convertFrom24To12Format(item)}
                                />
                              );
                            }
                          )}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.54)' }}
                    >
                      Note: order pickup is 1 hour from selected delivery time
                    </Grid>
                  </>
                ) : (
                  <Typography>Select Delivery Date</Typography>
                )}
              </Grid>
            </Grid>
            <NotesGrid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Notes"
                multiline
                name="notes"
                value={data.notes}
                onChange={(e) => handleChange('notes', e)}
                rows={3}
              />
            </NotesGrid>
            {props.type === FormType.CREATE && (
              <Typography align="center" style={{ fontSize: '12px' }}>
                This form will create a new order for the selected client. If
                you wish to edit an existing order please view the respective
                order tab
              </Typography>
            )}
            <Grid container justify="flex-end" style={{ margin: '10px 0' }}>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !(
                    data.name &&
                    data.phone &&
                    data.line1 &&
                    data.city &&
                    data.province &&
                    data.country &&
                    data.postalCode &&
                    data.date &&
                    (isAdmin || data.deliveryTime) &&
                    !error.phone &&
                    !error.postalCode &&
                    !formIsDirty
                  )
                }
                onClick={handleSubmit}
              >
                {props.type === FormType.CREATE ? 'Create' : 'Update'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </div>
  );
};

const NotesGrid = styled(Grid)`
  margintop: 10px;
`;
