import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../common/Spinner';
import { getAdminHistory } from '../redux/actions/getAdminHistoryAction';
import { CellParams, DataGrid } from '@material-ui/data-grid';
import { Grid, Link } from '@material-ui/core';
import { HistoryChanges } from './historyChanges';

interface Props {
    email: string
}

const GetHistory = (props: Props) => {
    const dispatch = useDispatch();
    const fetching = useSelector((state: any) => state?.getAdminHistory?.isFetching);
    const fetched = useSelector((state: any) => state?.getAdminHistory?.fetched);
    const history = useSelector((state: any) => state?.getAdminHistory?.history);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        let unmounted = false;
    
        if(!unmounted) {
            dispatch(getAdminHistory(props.email));
        }
    
        return () => {
          unmounted = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    if (!fetched || fetching) {
        return <Spinner />;
    }

    const handeClose = () => {
        setOpen(false);
        setData({});
    };
    const handleOpen = (actionType: string, data: any) => {
        setOpen(true);
        setData({...data, actionType});
    };

    const viewDetails = (actionType: string, changes: any) => {
        handleOpen(actionType, changes);
    }

    const columns: any = [
        { field: 'itemId', headerName: 'Order ID', flex: 1 },
        { field: 'actionType', headerName: 'Action Type', flex: 1 },
        { field: 'actionDate', headerName: 'Action Date', flex: 1},
        { field: 'actionTime', headerName: 'Action Time', flex: 1}
    ];

    const rows = history.map(h => {
        return {
            ...h,
            id: h.historyId
        }
    });

    columns.push({
        field: 'actionDetails',
        headerName: 'Action Details',
        flex: 1,
        renderCell: (params: CellParams) => {
            if (params.row.actionType === 'DELETE') return <span className={`row-${params.row.isAdmin ? 'admin' : ''}`}></span>;
            return (
                <div className={`row-${params.row.isAdmin ? 'admin' : ''}`}>
                    <Link
                        component="button"
                        onClick={() => viewDetails(params.row.actionType, params.row.actionDetails)}>
                            {params.row.actionType === 'CREATE'
                                ? 'View Details'
                                : params.row.actionType === 'EDIT'
                                    ? 'View Changes'
                                    : ''
                            }
                    </Link>
                </div>
        )}
    });

    return (
        <>
            <Grid item style={{ minHeight: '72vh', width: '100%' }}>
                <DataGrid
                    showToolbar
                    density="standard"
                    rowHeight={50}
                    rows={rows.map(row => {
                        return {
                            ...row
                        }
                    })}
                    columns={columns}
                    pageSize={20}
                />
            </Grid>
            {open && (
            <HistoryChanges
                open={open}
                data={data}
                handleClose={handeClose}
            />
            )}
        </>
    );
};

export default GetHistory;
  