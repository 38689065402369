import { CALL_API } from '../middleware/api';

export const GET_ADMIN_ANALYTICS_REQUEST = 'GET_ADMIN_ANALYTICS_REQUEST';
export const GET_ADMIN_ANALYTICS_SUCCESS = 'GET_ADMIN_ANALYTICS_SUCCESS';
export const GET_ADMIN_ANALYTICS_FAILURE = 'GET_ADMIN_ANALYTICS_FAILURE';

export function getAdminAnalytics(data: any) {
  return {
    [CALL_API]: {
      endpoint: 'admin/analytics/',
      authenticated: true,
      method: 'GET',
      data,
      types: [GET_ADMIN_ANALYTICS_REQUEST, GET_ADMIN_ANALYTICS_SUCCESS, GET_ADMIN_ANALYTICS_FAILURE],
    },
  };
}
