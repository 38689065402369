import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../common/Spinner';
import { ClientData } from '../../model';
import { getAdminCustomers } from '../../redux/actions/getAdminCustomers';
import CustomerCard from './CustomerCard';

const CustomerList = () => {
  const dispatch = useDispatch();

  const customers = useSelector(
    (state: any) => state.getAdminCustomers.customers
  );
  const fetched = useSelector(
    (state: any) => state.getAdminCustomers.customersFetched
  );
  const fetching = useSelector(
    (state: any) => state.getAdminCustomers.isFetching
  );

  useEffect(() => {
    let unmounted = false;

    if (!unmounted && !fetched) {
      dispatch(getAdminCustomers());
    }

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderNoClients = () => {
    return (
      <>
        <Typography variant="h5" align="center" style={{ marginTop: '20px' }}>
          No Customers Found.
        </Typography>
      </>
    );
  };

  const renderClients = () => {
    return (
      customers?.map((customer: ClientData, index: number) => {
        return (
          <CustomerCard key={`customer-${index}`} clientData={customer} />
        );
      })
    );
  };

  if (fetching) {
    return <Spinner />;
  }

  return (
    <div style={{ minHeight: '90vh' }}>
      {customers && customers?.length ? renderClients() : renderNoClients()}
    </div>
  );
};

export default CustomerList;
