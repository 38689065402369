import { CALL_API } from '../middleware/api';

import { ClientData } from '../../model';

export const EDIT_CLIENT_ADMIN_REQUEST = 'EDIT_CLIENT_ADMIN_REQUEST';
export const EDIT_CLIENT_ADMIN_SUCCESS = 'EDIT_CLIENT_ADMIN_SUCCESS';
export const EDIT_CLIENT_ADMIN_FAILURE = 'EDIT_CLIENT_ADMIN_FAILURE';

export function editClientAdmin(data: ClientData) {
  return {
    [CALL_API]: {
      endpoint: 'admin/editclients',
      authenticated: true,
      method: 'POST',
      data,
      types: [
        EDIT_CLIENT_ADMIN_REQUEST,
        EDIT_CLIENT_ADMIN_SUCCESS,
        EDIT_CLIENT_ADMIN_FAILURE,
      ],
    },
  };
}
