// actions/snackbarActions.js
export const showSnackbar = (message: string, snackbarType: string) => {
  return (dispatch: any) => {
    dispatch({ type: 'SNACKBAR_SUCCESS', message, snackbarType });

    setTimeout(function () {
      dispatch({ type: 'SNACKBAR_CLEAR' });
    }, 2000);
  };
};

export const clearSnackbar = () => {
  return (dispatch: any) => {
    dispatch({ type: 'SNACKBAR_CLEAR' });
  };
};
