import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ClientData, OrderData } from '../model';
import {
  deleteClient,
  DELETE_CLIENT_SUCCESS,
} from '../redux/actions/deleteClientAction';
import { getClients } from '../redux/actions/getClientsActions';
import { showSnackbar } from '../redux/actions/uiActions';

interface Props {
  clientData: ClientData;
  handleOpenOrder: (data: OrderData) => void;
  handleOpenClient: (data: ClientData) => void;
}

const ClientCard = (props: Props) => {
  const [confirm, setConfirm] = useState(false);
  const dispatch = useDispatch();

  const handeClose = () => {
    setConfirm(false);
  };

  const handleDeleteClicked = () => {
    setConfirm(true);
  };

  const confirmDelete = async () => {
    setConfirm(false);
    try {
      let result: any = null;
      result = await dispatch(deleteClient(props?.clientData?.clientId));

      if (result?.type === DELETE_CLIENT_SUCCESS) {
        dispatch(showSnackbar('Action completed', 'success'));
        dispatch(getClients());
      } else {
        dispatch(showSnackbar('Action failed', 'error'));
      }
    } catch (e: any) {}
  };

  return (
    <>
      {confirm && (
        <Dialog
          open={confirm}
          onClose={handeClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Are you sure you want to delete this client?'}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handeClose} color="primary">
              NO
            </Button>
            <Button onClick={confirmDelete} color="primary" autoFocus>
              YES
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Card
        variant="outlined"
        color="primary"
        style={{ height: '80px', margin: '15px' }}
      >
        <CardContent>
          <Grid container alignItems="center">
            <Grid container item xs={7}>
              <Grid item xs={6}>
                <Typography>
                  <b>Name: </b>
                  {props.clientData.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <b>City: </b>
                  {props.clientData.city}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <b>Postal Code: </b>
                  {props.clientData.postalCode}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <b>Phone: </b>
                  {props.clientData.phone}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item direction="row" justify="flex-end" xs={5}>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '15px' }}
                  onClick={() => props.handleOpenClient(props.clientData)}
                >
                  Edit Client
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  style={{ marginRight: '15px' }}
                  color="primary"
                  onClick={() => props.handleOpenOrder(props.clientData)}
                >
                  Create Order
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleDeleteClicked}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ClientCard;
