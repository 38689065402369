import { CircularProgress, Grid } from '@material-ui/core';
import React from 'react';

const Spinner = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '90vh' }}
    >
      <CircularProgress size={50} />
    </Grid>
  );
};

export default Spinner;
