import {
    CREATE_ORDER_REQUEST,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAILURE,
  } from '../actions/createOrderAction';
  
  function createOrder(
    state = {
      isFetching: false
    },
    action: any
  ) {
    switch (action.type) {
      case CREATE_ORDER_REQUEST:
        return Object.assign({}, state, {
          isFetching: true
        });
      case CREATE_ORDER_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false
        });
      case CREATE_ORDER_FAILURE:
        return Object.assign({}, state, {
          isFetching: false
        });
      default:
        return state;
    }
  }
  
  export default createOrder;
  