import {
  Box,
  Grid,
  IconButton,
  InputBase,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ClientCardAdmin from './ClientCardAdmin';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import { ClientData } from '../../model';
import { FormCreateLoction, FormType } from '../../constants';
import ClientFormAdmin from './ClientFormAdmin';
import { Pagination } from '@material-ui/lab';
import { OrderForm } from '../../Order/OrderForm';
import { getAdminOrderInfo } from '../../redux/actions/getAdminOrderInfo';

interface Props {
  email: string;
}

const ITEMS_PER_PAGE = 5;

const ClientListAdmin = (props: Props) => {
  const clients = useSelector((state: any) => state.getAdminOrderInfo?.clients);
  const fetched = useSelector((state: any) => state.getAdminOrderInfo?.fetched);
  let prevCount = -1;

  const [activeClient, setActiveClient] = useState(null);
  const [formType, setFormType] = useState(FormType.CREATE);
  const [search, setSearch] = useState('');

  const [flags, setFlags] = useState({
    clientOpen: false,
    orderOpen: false
  });

  const [page, setPage] = React.useState(1);

  const handleChange = (event: any, value: number) => {
    setPage(value);
  };

  const handleSearchChange = (e: any) => {
    const value = e?.target?.value;
    setPage(1);
    setSearch(value);
  };

  const handeCloseClient = () => {
    setActiveClient(null);
    setFlags({
      ...flags,
      clientOpen: false,
    });
  };

  const handleEditClient = (data: any) => {
    setFlags({
      ...flags,
      clientOpen: true,
    });
    setActiveClient(data);
    setFormType(FormType.EDIT);
  };
  
  const handeCloseOrder = () => {
    setFlags({
      ...flags,
      orderOpen: false,
    });
    setActiveClient(null);
  };
  const handleOpenOrder = (data: any) => {
    setFlags({
      ...flags,
      orderOpen: true,
    });
    setActiveClient(data);
  };
  
  const dispatch = useDispatch();
  const { email } = props;
  
  useEffect(() => {
    let unmounted = false;

    !unmounted && !fetched && dispatch(getAdminOrderInfo(email));

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderNoClients = () => {
    return (
      <>
        <Typography variant="h5" align="center" style={{ marginTop: '20px' }}>
          No Clients Found.
        </Typography>
        <Typography align="center">
          At least one client is needed to create an order.
        </Typography>
      </>
    );
  };

  const renderClients = () => {
    const filteredClients = !search
      ? clients
      : clients.filter(
          (client: any) =>
            client.name?.toLowerCase().includes(search?.toLowerCase()) ||
            client.phone?.includes(search?.toLowerCase())
        );

    const onPageClients = filteredClients.slice(
      (page - 1) * ITEMS_PER_PAGE,
      (page - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );

    prevCount = Math.round(filteredClients.length / ITEMS_PER_PAGE);

    if (!onPageClients || !onPageClients.length) {
      return <Typography align="center">No matching clients found.</Typography>;
    }

    return onPageClients?.map((client: ClientData) => {
      return (
        <ClientCardAdmin
          key={client.clientId}
          handleOpenClient={handleEditClient}
          handleOpenOrder={handleOpenOrder}
          clientData={client}
        />
      );
    });
  };

  return (
    <>
      {flags.clientOpen && (
        <ClientFormAdmin
          type={formType}
          data={activeClient}
          open={flags.clientOpen}
          handleClose={handeCloseClient}
          email={props.email}
        />
      )}
      {flags.orderOpen && (
        <OrderForm
          type={FormType.CREATE}
          open={flags.orderOpen}
          data={activeClient}
          location={FormCreateLoction.CLIENTLIST}
          handleClose={handeCloseOrder}
        />
      )}
      <Box
        marginTop={'2px'}
        style={{ backgroundColor: 'white', padding: '0 0 40px 0', minHeight: '72vh' }}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignContent="center"
          style={{ padding: '15px 0', width: '100%' }}
        >
          <Grid
            item
            container
            alignContent="center"
            justify="space-between"
            style={{
              height: '40px',
              border: '1px solid grey',
            }}
            xs={9}
          >
            <InputBase
              placeholder="Search clients by name or phone"
              inputProps={{ 'aria-label': 'search' }}
              value={search}
              onChange={handleSearchChange}
              style={{ padding: '0 10px', width: '80%' }}
              disabled={flags.clientOpen}
            />
            <IconButton
              type="submit"
              aria-label="search"
              style={{ padding: '0 10px' }}
            >
              <SearchIcon />
            </IconButton>
          </Grid>
        </Grid>
        {clients && clients.length ? renderClients() : renderNoClients()}
        {prevCount > 0 && (
          <Grid item container alignContent="center" justify="center">
            <Pagination
              count={prevCount}
              color="primary"
              onChange={handleChange}
            />
          </Grid>
        )}
      </Box>
    </>
  );
};

export default ClientListAdmin;
