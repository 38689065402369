import React from 'react';
import { AppBar, Grid, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import styled from 'styled-components';

export const Footer = () => {
  return (
    <AppBar
      id="contactus"
      position="static"
      style={{ background: '#1a1a1a', marginTop: 'auto', position: 'relative'}}
    >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ height: '60px', padding: '0 20px' }}
        direction="row"
      >
        <Grid item>
          <Typography variant="caption" style={{ color: 'white' }}>
            GTASWIFT
          </Typography>
        </Grid>
        <Grid item>
          <StyledGrid container>
            <Grid
              item
              style={{ paddingRight: '3px', color: 'rgb(0, 131, 255)' }}
            >
              <EmailIcon />
            </Grid>
            <Grid item>
              <Typography variant="caption">
                <a
                  style={{ color: 'white', textDecoration: 'none' }}
                  href="mailto:gtaswiftlogistics@gmail.com"
                >
                  gtaswiftlogistics@gmail.com
                </a>
              </Typography>
            </Grid>
          </StyledGrid>
        </Grid>
        <Grid item>
          <StyledGrid container>
            <Grid
              item
              style={{ paddingRight: '3px', color: 'rgb(0, 131, 255)' }}
            >
              <CallIcon />
            </Grid>
            <Grid item>
              <Typography variant="caption">
                <a
                  style={{ color: 'white', textDecoration: 'none' }}
                  href="tel:647-668-0833"
                >
                  647-668-0833
                </a>
              </Typography>
            </Grid>
          </StyledGrid>
        </Grid>
        <Grid item>
          <Typography variant="caption" style={{ color: 'white' }}>
            © Copyright. All Rights Reserved
          </Typography>
        </Grid>
      </Grid>
    </AppBar>
  );
};

const StyledGrid = styled(Grid)`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
