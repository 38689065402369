import React from "react"
import DateFnsUtils from "@date-io/date-fns"
import { Button, Dialog, DialogActions, DialogTitle, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { convertFrom24To12Format, formatDate, getDeliveryTimes, getMaxDate, getMinDate } from "../helper/date"

interface Props {
    open: boolean;
    isAdmin: boolean;
    profile: any;
    handleClose: () => void;
    handleSubmit: (date: string, time: string) => void;
}

export const DateTimePicker = (props: Props) => {
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [selectedTime, setSelectedTime] = React.useState('');
    let dateMap = props.profile?.allowedTime ?? {};
    let allowedDays = props.profile?.allowedDays
    allowedDays = allowedDays ? allowedDays : Array.from(Array(7).keys());

    function disableDays(date: any) {
        return !allowedDays.includes(date.getDay());
    }
    
    const handleDateSelect = (e) => {
        setSelectedDate(e);
        setSelectedTime('');
    }
    const handleTimeSelect = (e) => {
        setSelectedTime(e?.target?.value);
    }
 
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
        >
            <DialogTitle id="alert-dialog-title">
            {'Pick a Date and Delivery time for the orders'}
            </DialogTitle>
            <DialogActions>
                <Grid container direction="column" alignItems="flex-end">
                    <Grid item>
                        <Grid container direction="row" justify="space-between" style={{padding: '24px'}}>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        label="Delivery Date"
                                        style={{ paddingRight: '1rem' }}
                                        value={selectedDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        minDate={getMinDate(new Date(), dateMap, props.isAdmin)}
                                        maxDate={getMaxDate(new Date())}
                                        shouldDisableDate={disableDays}
                                        required
                                        onChange={handleDateSelect}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                            {selectedDate ? (
                                <>
                                    <Grid item>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                        name="controlled-radio-buttons-group"
                                        value={selectedTime}
                                        onChange={(e) => handleTimeSelect(e)}
                                        >
                                        {getDeliveryTimes(selectedDate, dateMap).map(
                                            (item, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={item}
                                                        control={<Radio />}
                                                        label={convertFrom24To12Format(item)}
                                                    />
                                                );
                                            }
                                        )}
                                        </RadioGroup>
                                    </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.54)' }}
                                    >
                                        Note: order pickup is 1 hour from selected delivery time
                                    </Grid>
                                </>
                                ) : (
                                <Typography>Select Delivery Date</Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{paddingRight: '24px'}}>
                        <Button
                            disabled={!(selectedDate && (selectedTime || props.isAdmin))}
                            onClick={() => props.handleSubmit(formatDate(selectedDate), selectedTime)}
                            color="primary" size="small"
                            variant="contained"
                            autoFocus>
                            Continue
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}