import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import DetailsList from './DetailsList';

function getSteps() {
  return [
    'What would you like delivered today?',
    'Where is your delivery going?',
    'How fast do you need it delivered?',
  ];
}

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return `Create an order through the GTASwift portal`;
    case 1:
      return 'Pick a destination for your order';
    case 2:
      return `Select the date you want your order delivered by`;
    default:
      return 'Unknown step';
  }
}

const AboutUs = () => {
  const steps = getSteps();

  return (
    <div
      style={{
        padding: '60px',
        background: '#151515',
      }}
    >
      <Grid container direction="column" alignItems="center">
        <DetailsList />
        <hr style={{ width: '100%', color: 'white' }} />
        <Grid
          container
          item
          style={{ marginTop: '1px', paddingTop: '30px' }}
          alignItems="center"
          justify="space-around"
        >
          <Grid>
            <Typography
              variant="h5"
              style={{ color: 'white', fontWeight: 'bolder' }}
            >
              3 EASY STEPS
            </Typography>
          </Grid>
          <Grid item>
            <Stepper
              orientation="vertical"
              style={{
                background: 'none',
                color: 'white',
                borderRadius: '15px',
              }}
            >
              {steps.map((label, index) => (
                <Step active={true} key={label} style={{ color: 'white' }}>
                  <StyledStepLabel style={{ color: 'white' }}>
                    <Typography style={{ color: 'white', fontWeight: 'bold' }}>
                      {label}
                    </Typography>
                  </StyledStepLabel>
                  <StepContent>
                    <Typography style={{ color: 'white' }}>
                      {getStepContent(index)}
                    </Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const StyledStepLabel = styled(StepLabel)`
  .MuiStepIcon-root.MuiStepIcon-active {
    color: #0083ff;
  }
`;

export default AboutUs;
