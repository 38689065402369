import React from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
} from '@material-ui/core';
import { Props } from './model';

export const CustomerInfoView = (props: Props) => {
  const handleEdit = (e: any) => {
    e.preventDefault();
    props.showForm();
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={6}>
            <Typography color="textSecondary" gutterBottom>
              <b>Name:</b> {props.data.name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary" gutterBottom>
              <b>Company Name:</b> {props.data.pharmacyName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary" gutterBottom>
              <b>Email:</b> {props.data.email}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary" gutterBottom>
              <b>Address:</b> {props.data.line1}
              {props.data.line2}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary" gutterBottom>
              <b>City:</b> {props.data.city}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary" gutterBottom>
              <b>Country:</b> {props.data.country}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary" gutterBottom>
              <b>Postal Code:</b> {props.data.postalCode}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textSecondary" gutterBottom>
              <b>Phone:</b> {props.data.phone}
            </Typography>
          </Grid>
          <Grid container justify="flex-end" style={{ margin: '10px 0' }}>
            <CardActions>
              <Button variant="contained" color="primary" onClick={handleEdit}>
                Edit
              </Button>
            </CardActions>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
