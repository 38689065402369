import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Grid } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { OrderType } from '../constants';

interface IProps {
  minDate: MaterialUiPickersDate;
  maxDate: MaterialUiPickersDate;
  setMinDate: (date: MaterialUiPickersDate) => void;
  setMaxDate: (date: MaterialUiPickersDate) => void;
  dateRangeType: string;
  showOrderCount?: boolean;
  orderCount?: number;
  className?: string
}

const DateRangePicker = ({
  minDate,
  maxDate,
  setMinDate,
  setMaxDate,
  dateRangeType,
  showOrderCount,
  orderCount,
  className
}: IProps) => {
  const handleDateChange = (day: string, e: any) => {
    if (day === 'Start') {
      setMinDate(e);
    } else {
      setMaxDate(e);
    }
  };

  return (
    <>
      <Grid
        container
        className={className}
        direction="row"
        justify="center"
        alignItems="center"
        style={{ margin: '.75rem 0' }}
      >
        <Grid item style={{ marginRight: '.75rem' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              disableToolbar
              autoOk={true}
              label="Start Date"
              value={minDate}
              minDate={
                dateRangeType === OrderType.CURRENT
                  ? new Date().setFullYear(new Date().getFullYear() - 2)
                  : (dateRangeType === OrderType.FUTURE
                    ? new Date().setDate(new Date().getDate() + 1)
                    : new Date().setDate(new Date().getDate() - 90))
              }
              maxDate={
                dateRangeType === OrderType.CURRENT
                  ? new Date().setDate(new Date().getDate())
                  : (dateRangeType === OrderType.FUTURE 
                    ? new Date().setDate(new Date().getDate() + 90)
                    : new Date().setDate(new Date().getDate() - 1))
              }
              clearable
              clearLabel="clear"
              onChange={(e) => handleDateChange('Start', e)}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              disableToolbar
              autoOk={true}
              label="End Date"
              value={maxDate}
              minDate={minDate ??
                (
                  dateRangeType === OrderType.CURRENT
                  ? new Date().setFullYear(new Date().getFullYear() - 2)
                  : (dateRangeType === OrderType.FUTURE
                    ? new Date().setDate(new Date().getDate() + 1)
                    : new Date().setDate(new Date().getDate() - 90))
                )
              }
              maxDate={
                dateRangeType === OrderType.CURRENT
                ? new Date().setDate(new Date().getDate())
                : (dateRangeType === OrderType.FUTURE
                  ? new Date().setDate(new Date().getDate() + 90)
                  : new Date().setDate(new Date().getDate() - 1))
              }
              clearable
              clearLabel="clear"
              onChange={(e) => handleDateChange('End', e)}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        { showOrderCount &&
          <Grid
            item
            style={{
              border: '1px solid black',
              padding: '.5rem',
              marginLeft: '.75rem',
            }}
          >
            {`Order Count: ${
              minDate && maxDate ? orderCount : 0
            }`}
          </Grid>
        }
      </Grid>
    </>
  );
};

export default DateRangePicker;
