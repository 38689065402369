import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Tooltip,
  styled,
  TooltipProps,
  IconButton,
  Dialog,
  DialogTitle,
  Box,
  DialogActions,
  InputBase,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import { useHistory } from 'react-router-dom';
import React from 'react';
import DaySelector from './DaySelector';
import { convertFrom24To12Format } from '../../helper/date';
import { DayToNameMap } from '../../constants';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from '../../redux/actions/uiActions';
import { getAdminCustomers } from '../../redux/actions/getAdminCustomers';
import {
  deletePharmacyAdmin,
  DELETE_PHARMACY_ADMIN_SUCCESS,
} from '../../redux/actions/deletePharmacyAdmin';

interface Props {
  clientData: any;
}

const CustomerCard = (props: Props) => {
  const history = useHistory();

  const handleView = () => {
    history.push(`/admin/${props.clientData?.email}/customer`);
  };

  const dispatch = useDispatch();
  const [showSelectDateModal, setShowSelectDateModal] = React.useState(false);
  const [showDeletePopup, setShowDeletePopup] = React.useState(false);
  const [inputCode, setInputCode] = React.useState('');

  const code = useSelector((state: any) => state?.getProfile?.profile?.code);

  const handleCodeChange = (e: any) => {
    const value = e?.target?.value;
    setInputCode(value);
  };

  const onClose = () => {
    setShowSelectDateModal(false);
  };

  const handleDelete = () => {
    setShowDeletePopup(true);
  };

  const handleDeleteClose = () => {
    setShowDeletePopup(false);
  };

  const handleConfirmDeleteClick = async () => {
    if (code === inputCode) {
      let result: any = null;
      result = await dispatch(deletePharmacyAdmin(props.clientData?.email));

      if (result?.type === DELETE_PHARMACY_ADMIN_SUCCESS) {
        dispatch(showSnackbar('Pharmacy Deleted', 'success'));
        dispatch(getAdminCustomers());
      } else {
        dispatch(showSnackbar('Action failed', 'error'));
      }

      handleDeleteClose();
    } else {
      dispatch(showSnackbar('Incorrect Code', 'error'));
      handleDeleteClose();
    }
  };

  const renderConfirmModal = () => {
    if (!showDeletePopup) {
      return null;
    }

    return (
      <Dialog
        open={showDeletePopup}
        maxWidth="sm"
        fullWidth
        onClose={handleDeleteClose}
      >
        <DialogTitle>
          Enter Code to Delete {props.clientData?.pharmacyName}
        </DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={handleDeleteClose}>
            <Close />
          </IconButton>
        </Box>
        <InputBase
          placeholder="Code"
          value={inputCode}
          onChange={handleCodeChange}
          style={{
            margin: '10px 30px',
            width: '50%',
            borderBottom: '1px grey solid',
          }}
        />
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleDeleteClose}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleConfirmDeleteClick}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: 'rgba(97, 97, 97, 0.95)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  return (
    <>
      {showSelectDateModal ? (
        <DaySelector
          open={showSelectDateModal}
          handleClose={onClose}
          clientData={props.clientData}
        />
      ) : null}
      {renderConfirmModal()}
      <Card
        variant="outlined"
        color="primary"
        style={{ height: '80px', margin: '15px' }}
      >
        <CardContent>
          <Grid container alignItems="center">
            <Grid container item xs={9}>
              <Grid item xs={6}>
                <Typography>
                  <b>Name: </b>
                  {props.clientData?.pharmacyName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <b>Email: </b>
                  {props.clientData?.email}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <b>Phone: </b>
                  {props.clientData?.phone}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="row"
              justify="flex-end"
              alignItems="center"
              xs={3}
            >
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '0.75rem' }}
                  onClick={() => {
                    setShowSelectDateModal(true);
                  }}
                >
                  Edit Days
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '0.75rem' }}
                  onClick={handleView}
                >
                  view
                </Button>
              </Grid>
              <Grid item>
                <HtmlTooltip
                  title={
                    <Grid container direction="column">
                      {props?.clientData?.allowedDays
                        ?.sort((a, b) => a - b)
                        .map((slot) => {
                          return (
                            <Grid item key={`slot-${slot}`}>
                              {`${DayToNameMap[slot]}: 
                                ${convertFrom24To12Format(
                                  props.clientData.allowedTime[slot]?.timeSlotA
                                )}${
                                props.clientData.allowedTime[slot]?.timeSlotB
                                  ? ','
                                  : ''
                              }
                                ${
                                  convertFrom24To12Format(
                                    props.clientData.allowedTime[slot]
                                      ?.timeSlotB
                                  ) ?? ''
                                }`}
                            </Grid>
                          );
                        })}
                    </Grid>
                  }
                >
                  <IconButton color="primary" style={{ padding: 0 }}>
                    <InfoIcon></InfoIcon>
                  </IconButton>
                </HtmlTooltip>
              </Grid>
              <Grid item>
                <IconButton color="primary" onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CustomerCard;
