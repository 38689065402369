import { CALL_API } from '../middleware/api';

export const DELETE_PHARMACY_ADMIN_REQUEST = 'DELETE_PHARMACY_ADMIN_REQUEST';
export const DELETE_PHARMACY_ADMIN_SUCCESS = 'DELETE_PHARMACY_ADMIN_SUCCESS';
export const DELETE_PHARMACY_ADMIN_FAILURE = 'DELETE_PHARMACY_ADMIN_FAILURE';

export function deletePharmacyAdmin(email: string) {
  return {
    [CALL_API]: {
      endpoint: `admin/deletePharmacy/${email}`,
      authenticated: true,
      method: 'DELETE',
      types: [
        DELETE_PHARMACY_ADMIN_REQUEST,
        DELETE_PHARMACY_ADMIN_SUCCESS,
        DELETE_PHARMACY_ADMIN_FAILURE,
      ],
    },
  };
}
