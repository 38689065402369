import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { Grid } from '@material-ui/core';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ProfileData } from '../../model';
import {
  adminEditProfile,
  ADMIN_EDIT_PROFILE_SUCCESS,
} from '../../redux/actions/adminEditProfileAction';
import { showSnackbar } from '../../redux/actions/uiActions';
import { useDispatch } from 'react-redux';
import { getAdminCustomers } from '../../redux/actions/getAdminCustomers';
import { cloneDeep } from 'lodash';
import { DayToNameMap } from '../../constants';

interface Props {
  open: boolean;
  handleClose: () => void;
  clientData: any;
}

const DaySelector = (props: Props) => {
  const dispatch = useDispatch();
  const array = props.clientData.allowedDays ?? Array.from(Array(7).keys());
  const allowedTimeFromDB = props.clientData.allowedTime ?? {};
  const allowedTimeObj = {
    0: {
      timeSlotA: allowedTimeFromDB?.[0]?.['timeSlotA'] ?? '12:00',
      timeSlotB: allowedTimeFromDB?.[0]?.['timeSlotB'] ?? null,
    },
    1: {
      timeSlotA: allowedTimeFromDB?.[1]?.['timeSlotA'] ?? '12:00',
      timeSlotB: allowedTimeFromDB?.[1]?.['timeSlotB'] ?? null,
    },
    2: {
      timeSlotA: allowedTimeFromDB?.[2]?.['timeSlotA'] ?? '12:00',
      timeSlotB: allowedTimeFromDB?.[2]?.['timeSlotB'] ?? null,
    },
    3: {
      timeSlotA: allowedTimeFromDB?.[3]?.['timeSlotA'] ?? '12:00',
      timeSlotB: allowedTimeFromDB?.[3]?.['timeSlotB'] ?? null,
    },
    4: {
      timeSlotA: allowedTimeFromDB?.[4]?.['timeSlotA'] ?? '12:00',
      timeSlotB: allowedTimeFromDB?.[4]?.['timeSlotB'] ?? null,
    },
    5: {
      timeSlotA: allowedTimeFromDB?.[5]?.['timeSlotA'] ?? '12:00',
      timeSlotB: allowedTimeFromDB?.[5]?.['timeSlotB'] ?? null,
    },
    6: {
      timeSlotA: allowedTimeFromDB?.[6]?.['timeSlotA'] ?? '12:00',
      timeSlotB: allowedTimeFromDB?.[6]?.['timeSlotB'] ?? null,
    },
  };

  const convertToDate = (timeString: string) => {
    if (!timeString) {
      return null;
    }
    const stringArray = timeString?.split(':');
    const hour = parseInt(stringArray[0]);
    const minutes = parseInt(stringArray[1]);
    const date = new Date();
    date.setHours(hour);
    date.setMinutes(minutes);
    return date;
  };

  const [state, setState] = React.useState(array);
  const [allowedTime, setAllowedTime] = React.useState(allowedTimeObj);

  const handleChange = (number: any) => (_event) => {
    if (state.includes(number)) {
      let newArray = [...state];
      newArray = newArray.filter((item) => item !== number);
      setState(newArray);
    } else {
      setState([...state, number]);
    }
  };

  const updateDates = async () => {
    const data: ProfileData = {
      ...props.clientData,
      admin: undefined,
      allowedDays: state,
      allowedTime: allowedTime,
    };
    const result: any = await dispatch(adminEditProfile(data));

    if (result?.type === ADMIN_EDIT_PROFILE_SUCCESS) {
      dispatch(showSnackbar('Action completed', 'success'));
      props.handleClose();
    } else {
      dispatch(showSnackbar('Action failed', 'error'));
      props.handleClose();
    }
    await dispatch(getAdminCustomers());
  };

  const handleDateChange = async (
    timeslot: string,
    dayIndex: number,
    date: any
  ) => {
    let cutoff: string | null = null;

    if (date) {
      const formattedMinutes = String(date.getMinutes()).padStart(2, '0');
      cutoff = `${date.getHours()}:${formattedMinutes}`;
    }

    const allowedTimeClone = cloneDeep(allowedTime);
    if (timeslot === 'timeSlotA') {
      allowedTimeClone[dayIndex]['timeSlotA'] = cutoff;
    } else {
      allowedTimeClone[dayIndex]['timeSlotB'] = cutoff;
    }

    setAllowedTime(allowedTimeClone);
  };

  return (
    <Dialog color="white" open={props.open} onClose={props.handleClose}>
      <Box style={{ minWidth: 500, width: '100%' }} padding="20px 40px">
        <Grid container direction="column">
          <Typography align="center" variant="h5">
            Allowed Days
          </Typography>
          {Object.keys(DayToNameMap).map((i: any) => {
            return (
              <Grid container key={i}>
                <Grid item style={{ padding: '1rem' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.includes(parseInt(i))}
                        onChange={handleChange(parseInt(i))}
                        value={DayToNameMap[i]}
                      />
                    }
                    label={DayToNameMap[i]}
                  />
                  <Grid container>
                    <Grid item>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          id={`timeSlotA-${i}`}
                          style={{ width: '170px', paddingRight: '1rem' }}
                          label="Cut off time #1"
                          value={convertToDate(
                            (allowedTime[i] && allowedTime[i]['timeSlotA']) ||
                              '12:00'
                          )}
                          onChange={(e) => handleDateChange('timeSlotA', i, e)}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          id={`timeSlotB-${i}`}
                          style={{ width: '170px' }}
                          label="Cut off time #2"
                          emptyLabel="Timeslot not set"
                          value={
                            convertToDate(allowedTime[i]?.['timeSlotB']) ?? null
                          }
                          onChange={(e) => handleDateChange('timeSlotB', i, e)}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          clearable
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '15px' }}
            onClick={updateDates}
          >
            Update Dates
          </Button>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default DaySelector;
