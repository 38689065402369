import {
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILURE,
} from '../actions/getClientsActions';

function getClients(
  state = {
    isFetching: false,
    clients: null,
    fetched: false,
  },
  action: any
) {
  switch (action.type) {
    case GET_CLIENTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        clients: null,
      });
    case GET_CLIENTS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        clients: action?.response?.data?.Items,
        fetched: true,
      });
    case GET_CLIENTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
}

export default getClients;
