import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import {
  editProfile,
  EDIT_PROFILE_SUCCESS,
} from '../redux/actions/editProfileAction';
import { getProfile } from '../redux/actions/getProfileAction';
import { Props } from './model';
import { showSnackbar } from '../redux/actions/uiActions';

export const CustomerInfoForm = (props: Props) => {
  const [formIsDirty, setFormIsDirty] = useState(true);

  const [data, setData] = useState({
    name: props?.data?.name || '',
    phone: props?.data?.phone || '',
    line1: props?.data?.line1 || '',
    line2: props?.data?.line2 || '',
    city: props?.data?.city || '',
    province: props?.data?.province || '',
    postalCode: props?.data?.postalCode || '',
    country: props?.data?.country || '',
    pharmacyName: props?.data?.pharmacyName || '',
  });

  const dispatch = useDispatch();

  const handleChange = (type: string, event: any) => {
    const value = event?.target?.value;

    setFormIsDirty(false);

    setData({
      ...data,
      [type]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const result: any = await dispatch(editProfile(data));

    if (result?.type === EDIT_PROFILE_SUCCESS) {
      dispatch(showSnackbar('Action completed', 'success'));
      dispatch(getProfile());
    } else {
      dispatch(showSnackbar('Action failed', 'error'));
    }
  };

  return (
    <form>
      <Typography variant="subtitle1" align="center">
        Company Details
      </Typography>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            name="name"
            type="text"
            label="Name"
            value={data.name}
            onChange={(e) => handleChange('name', e)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            name="phone"
            type="phone"
            label="Phone"
            value={data.phone}
            onChange={(e) => handleChange('phone', e)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            label="Company Name"
            name="pharmacyName"
            value={data.pharmacyName}
            onChange={(e) => handleChange('pharmacyName', e)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            name="line1"
            type="text"
            label="Address Line 1"
            value={data.line1}
            onChange={(e) => handleChange('line1', e)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="line2"
            type="text"
            label="Address Line 2"
            value={data.line2}
            onChange={(e) => handleChange('line2', e)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            name="city"
            type="text"
            label="City"
            value={data.city}
            onChange={(e) => handleChange('city', e)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            name="province"
            type="text"
            label="Province"
            value={data.province}
            onChange={(e) => handleChange('province', e)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            name="postalCode"
            type="text"
            label="Postal Code"
            value={data.postalCode}
            onChange={(e) => handleChange('postalCode', e)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            name="country"
            type="text"
            label="Country"
            value={data.country}
            onChange={(e) => handleChange('country', e)}
          />
        </Grid>
        <Grid container justify="flex-end" style={{ margin: '10px 0' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={
              !(
                data.name &&
                data.phone &&
                data.line1 &&
                data.city &&
                data.province &&
                data.country &&
                data.postalCode &&
                data.pharmacyName &&
                !formIsDirty
              )
            }
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
