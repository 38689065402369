import { CALL_API } from '../middleware/api';

export const GET_ADMIN_CUSTOMERS_REQUEST = 'GET_ADMIN_CUSTOMERS_REQUEST';
export const GET_ADMIN_CUSTOMERS_SUCCESS = 'GET_ADMIN_CUSTOMERS_SUCCESS';
export const GET_ADMIN_CUSTOMERS_FAILURE = 'GET_ADMIN_CUSTOMERS_FAILURE';

export function getAdminCustomers() {
  return {
    [CALL_API]: {
      endpoint: 'admin/getcompanies',
      authenticated: true,
      method: 'GET',
      types: [
        GET_ADMIN_CUSTOMERS_REQUEST,
        GET_ADMIN_CUSTOMERS_SUCCESS,
        GET_ADMIN_CUSTOMERS_FAILURE,
      ],
    },
  };
}
