import { CALL_API } from '../middleware/api';

import { ProfileData } from '../../model';

export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILURE = 'EDIT_PROFILE_FAILURE';

export function editProfile(data: ProfileData) {
  return {
    [CALL_API]: {
      endpoint: 'updateprofile',
      authenticated: true,
      method: 'POST',
      data,
      types: [EDIT_PROFILE_REQUEST, EDIT_PROFILE_SUCCESS, EDIT_PROFILE_FAILURE],
    },
  };
}
