import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ClientCard from './ClientCard';
import ClientForm from './ClientForm';
import SearchIcon from '@material-ui/icons/Search';
import { OrderForm } from '../Order/OrderForm';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from '../redux/actions/getClientsActions';
import { ClientData } from '../model';
import { FormCreateLoction, FormType } from '../constants';
import Spinner from '../common/Spinner';

const ClientList = () => {
  const dispatch = useDispatch();
  const fetching = useSelector(
    (state: any) => state.getClients.isFetching || state?.editClient?.isFetching
  );
  const clients = useSelector((state: any) => state.getClients.clients);
  const fetched = useSelector((state: any) => state.getClients.fetched);

  const [activeClient, setActiveClient] = useState(null);
  const [formType, setFormType] = useState(FormType.CREATE);
  const [search, setSearch] = useState('');

  const [flags, setFlags] = useState({
    clientOpen: false,
    orderOpen: false,
  });

  useEffect(() => {
    let unmounted = false;

    !unmounted && !fetched && dispatch(getClients());

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (e: any) => {
    const value = e?.target?.value;
    setSearch(value);
  };

  const handeCloseClient = () => {
    setActiveClient(null);
    setFlags({
      ...flags,
      clientOpen: false,
    });
  };
  const handleCreateClient = () => {
    setFlags({
      ...flags,
      clientOpen: true,
    });
    setActiveClient(null);
    setFormType(FormType.CREATE);
  };

  const handleEditClient = (data: any) => {
    setFlags({
      ...flags,
      clientOpen: true,
    });
    setActiveClient(data);
    setFormType(FormType.EDIT);
  };

  const handeCloseOrder = () => {
    setFlags({
      ...flags,
      orderOpen: false,
    });
    setActiveClient(null);
  };
  const handleOpenOrder = (data: any) => {
    setFlags({
      ...flags,
      orderOpen: true,
    });
    setActiveClient(data);
  };

  const renderNoClients = () => {
    return (
      <>
        <Typography variant="h5" align="center" style={{ marginTop: '20px' }}>
          No Clients Found.
        </Typography>
        <Typography align="center">
          At least one client is needed to create an order.
        </Typography>
      </>
    );
  };

  const renderClients = () => {
    const filteredClients = !search
      ? clients
      : clients.filter(
          (client: any) =>
            client.name?.toLowerCase().includes(search?.toLowerCase()) ||
            client.phone?.includes(search?.toLowerCase())
        );

    if (!filteredClients || !filteredClients.length) {
      return <Typography align="center">No matching clients found.</Typography>;
    }

    return filteredClients?.map((client: ClientData) => {
      return (
        <ClientCard
          key={client.clientId}
          handleOpenClient={handleEditClient}
          handleOpenOrder={handleOpenOrder}
          clientData={client}
        />
      );
    });
  };

  if (!fetched || fetching) {
    return <Spinner />;
  }

  return (
    <>
      {flags.clientOpen && (
        <ClientForm
          type={formType}
          data={activeClient}
          open={flags.clientOpen}
          handleClose={handeCloseClient}
        />
      )}
      {flags.orderOpen && (
        <OrderForm
          type={FormType.CREATE}
          open={flags.orderOpen}
          data={activeClient}
          location={FormCreateLoction.CLIENTLIST}
          handleClose={handeCloseOrder}
        />
      )}
      <Box
        marginTop={'2px'}
        style={{ backgroundColor: 'white', padding: '0 0 40px 0' }}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignContent="center"
          style={{ padding: '15px', width: '100%' }}
        >
          <Grid
            item
            container
            alignContent="center"
            justify="space-between"
            style={{
              height: '40px',
              border: '1px solid grey',
            }}
            xs={9}
          >
            <InputBase
              placeholder="Search clients by name or phone"
              inputProps={{ 'aria-label': 'search' }}
              value={search}
              onChange={handleSearchChange}
              style={{ padding: '0 10px', width: '80%' }}
              disabled={flags.clientOpen || flags.orderOpen}
            />
            <IconButton
              type="submit"
              aria-label="search"
              style={{ padding: '0 10px' }}
            >
              <SearchIcon />
            </IconButton>
          </Grid>
          <Grid item container xs={3} justify="flex-end">
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleCreateClient}
            >
              Create Client
            </Button>
          </Grid>
        </Grid>
        {clients && clients.length ? renderClients() : renderNoClients()}
      </Box>
    </>
  );
};

export default ClientList;
