import React from 'react';
import { CardContent, Dialog, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles } from '@material-ui/core';

interface Props {
    data: any;
    open: boolean;
    handleClose: () => void;
}

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: '8px 0'
    },
  }));

export const HistoryChanges = (props: Props) => {
    const classes = useStyles();
    return (
        <div style={{ textAlign: 'center' }}>
            <Dialog color="white" open={props.open} onClose={props.handleClose}>
                {!!props.data.clientData &&
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            Client Info
                        </Typography>
                        <Typography color="textSecondary">
                            Name: {props.data.clientData.name}
                        </Typography>
                        <Typography color="textSecondary">
                            Address: {`${props.data.clientData.address}, ${props.data.clientData.city} ${props.data.clientData.province}, ${props.data.clientData.postalCode}`}
                        </Typography>
                        <Typography color="textSecondary">
                            Phone: {props.data.clientData.phone}
                        </Typography>
                        <Divider className={classes.margin} />
                        <Typography color="textSecondary">
                            Order Date: {props.data.clientData.date}
                        </Typography>
                        <Typography color="textSecondary">
                            Order Delivery Time: {props.data.clientData.deliveryTime}
                        </Typography>
                    </CardContent>
                }
                {props.data.actionType === 'CREATE' &&
                    <div style={{ padding: '16px' }}>
                        {props.data.clientInfo && <Divider className={classes.margin} />}
                        <Typography color="textSecondary">
                            Order create method: {props.data.createMethod}
                        </Typography>
                    </div>
                }
                {props.data.actionType === 'EDIT' &&
                    <TableContainer component={Paper} style={{ marginTop: '2px', padding: '16px' }}>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Field</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Old Value</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>New Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data && props.data.diff &&
                                    Object.keys(props.data.diff).map((key: any, index) =>
                                        <TableRow key={index}>
                                            <TableCell>{key}</TableCell>
                                            <TableCell>{props.data.diff[key]['old']}</TableCell>
                                            <TableCell>{props.data.diff[key]['new']}</TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Dialog>
        </div>
    )

}
