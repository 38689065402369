import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import DetailsCard from '../common/DetailsCard';

const DetailsMap = [
  {
    title: 'Seamless, from Start to Finish',
    detail:
      'Receive SMS notifications automatically so that a designated recipient can know when the delivery has started, when it can be expected, and when it is arriving.',
  },
  {
    title: 'No More Guessing',
    detail:
      'Real-time driver tracking makes it fun and easy for your clients to stay in the loop. With this added service, GTASWIFT is able to achieve faster delivery completion times and eliminate the “where’s my delivery?” calls.',
  },
  {
    title: 'We Connect with Our Customers',
    detail:
      'As a client, you will have the accessibility of communicating with the driver, the dispatcher and the call centre all through a single tap on your phone.',
  },
  {
    title: 'Instant Feedback',
    detail:
      'Our focus has always been our clients’ delivery experience. Now, GTASWIFT gets a first hand account of each of their customer’s experiences immediately after their delivery with the instant feedback button.',
  },
];

const DetailsList = () => {
  return (
    <Grid
      container
      style={{ padding: '10px 0 70px 0' }}
      justify="space-between"
      alignContent="center"
      direction="column"
    >
      <Typography
        align="center"
        variant="h5"
        style={{ color: 'white', fontWeight: 'bolder' }}
      >
        WHAT MAKES US DIFFERENT
      </Typography>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignContent="center"
        style={{ paddingTop: '30px' }}
      >
        {DetailsMap.map((detail, index) => {
          return (
            <DetailsCard
              key={index}
              title={detail.title}
              detail={detail.detail}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

export default DetailsList;
