import React, { useState } from 'react';
import { Paper, Grid, TextField, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';
import { loginUser } from '../redux/actions/authAction';
import { useDispatch, useSelector } from 'react-redux';
import { SignInReset } from './SignInReset';
import LoadingButton from '../common/LoadingButton';

export const SignInForm = () => {
  const reset = useSelector((state: any) => state.auth.resetPassword);
  const loginError = useSelector((state: any) => state.auth.errorMessage);
  const loading = useSelector((state: any) => state.auth.isFetching);

  const [cred, setCred] = useState({
    email: '',
    pass: '',
  });

  const dispatch = useDispatch();

  const handleChange = (type: string, event: any) => {
    const value = event?.target?.value;
    setCred({
      ...cred,
      [type]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(loginUser(cred));
  };

  return (
    <Grid container style={{ minHeight: '90vh' }}>
      {reset ? (
        <SignInReset />
      ) : (
        <Grid container direction="column" alignItems="center" justify="center">
          <StyledPaper>
            <Container maxWidth="xs">
              <StyledContainer>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                {loginError && (
                  <Typography align="center" color="error" component="p">
                    {loginError}
                  </Typography>
                )}
                <StyledForm noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={cred.email}
                    onChange={(e) => handleChange('email', e)}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={cred.pass}
                    onChange={(e) => handleChange('pass', e)}
                  />
                  <StyledButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!(cred.email && cred.pass) || loading}
                    loading={loading}
                  >
                    Sign In
                  </StyledButton>
                  <Grid container justify="center">
                    {/* <Link href="#" variant="body2">
                      Forgot password?
                    </Link> */}
                  </Grid>
                </StyledForm>
              </StyledContainer>
            </Container>
          </StyledPaper>
        </Grid>
      )}
    </Grid>
  );
};

const StyledForm = styled.form`
  width: 100%;
  margin-top: 8px;
`;

const StyledButton = styled(LoadingButton)`
  margin: 24px 0px 16px;
  height: 40px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledPaper = styled(Paper)`
  padding: 16px;
  max-width: 450px;
  margin-top: 20px;
`;
