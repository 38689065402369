import {
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createClient,
  CREATE_CLIENT_SUCCESS,
} from '../redux/actions/createClientActions';
import {
  editClient,
  EDIT_CLIENT_SUCCESS,
} from '../redux/actions/editClientAction';
import { getClients } from '../redux/actions/getClientsActions';
import { Fields, FormType } from '../constants';
import { ClientData } from '../model';
import { showSnackbar } from '../redux/actions/uiActions';
interface Props {
  type: FormType;
  data: ClientData | null;
  open: boolean;
  handleClose: () => void;
}

const ClientForm = (props: Props) => {
  const [error, setError] = useState({ phone: '', postalCode: '' });

  const [formIsDirty, setFormIsDirty] = useState(true);

  const [data, setData] = useState({
    name: props.data?.name || '',
    phone: props?.data?.phone || '',
    line1: props?.data?.line1 || '',
    line2: props?.data?.line2 || '',
    city: props?.data?.city || '',
    province: props?.data?.province || 'Ontario',
    postalCode: props?.data?.postalCode || '',
    country: props?.data?.country || 'Canada',
  });

  const dispatch = useDispatch();

  const validate = (field: string, val: string) => {
    let tempError: any = {};

    if (field === Fields.Phone) {
      const phoneno = /^\d{3}-\d{3}-\d{4}$/;
      if (val && val?.length && !val.match(phoneno)) {
        tempError.phone = 'phone number must match xxx-xxx-xxxx';
      } else {
        tempError.phone = '';
      }
    } else if (field === Fields.PostalCode) {
      const postalCode = /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/;
      if (val && val?.length && !val.match(postalCode)) {
        tempError.postalCode = 'please enter a valid postal code';
      } else {
        tempError.postalCode = '';
      }
    }

    setError({ ...error, ...tempError });
  };

  const handleChange = (type: string, event: any) => {
    let value = event?.target?.value;

    if (value && type === Fields.PostalCode) {
      value = value.toUpperCase();
    }

    setFormIsDirty(false);

    validate(type, value);

    setData({
      ...data,
      [type]: value,
    });
  };

  const { type } = props;

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let result: any = null;
    if (type === FormType.CREATE) {
      result = await dispatch(createClient(data));
    } else {
      result = await dispatch(
        editClient({ ...data, clientId: props?.data?.clientId })
      );
    }

    if (
      result?.type === CREATE_CLIENT_SUCCESS ||
      result?.type === EDIT_CLIENT_SUCCESS
    ) {
      dispatch(showSnackbar('Action completed', 'success'));
      dispatch(getClients());
    } else {
      dispatch(showSnackbar('Action failed', 'error'));
    }

    props.handleClose();
  };

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <Dialog
        style={{ width: '100%' }}
        color="white"
        open={props.open}
        onClose={props.handleClose}
      >
        <Box style={{ minWidth: 500, width: '100%' }} padding="30px 50px">
          <Typography align="center" variant="h5">
            {type === FormType.CREATE ? 'Create' : 'Edit'} Client
          </Typography>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                name="name"
                type="text"
                label="Name"
                value={data.name}
                helperText={'ex: John Doe'}
                onChange={(e) => handleChange('name', e)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                name="phone"
                type="phone"
                label="Phone"
                error={error.phone !== ''}
                helperText={error.phone}
                value={data.phone}
                onChange={(e) => {
                  handleChange('phone', e);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name="line1"
                type="text"
                label="Address Line 1"
                helperText={'ex: 1004 example street'}
                value={data.line1}
                onChange={(e) => handleChange('line1', e)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="line2"
                type="text"
                label="Address Details (Unit #, Buzzer, etc)"
                helperText={'ex: Unit 1, Buzzer: 1006'}
                value={data.line2}
                onChange={(e) => handleChange('line2', e)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                name="city"
                type="text"
                label="City"
                value={data.city}
                onChange={(e) => handleChange('city', e)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                name="province"
                type="text"
                disabled
                label="Province"
                value={data.province}
                onChange={(e) => handleChange('province', e)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                name="postalCode"
                type="text"
                label="Postal Code"
                value={data.postalCode}
                error={error.postalCode !== ''}
                helperText={error.postalCode}
                onChange={(e) => handleChange('postalCode', e)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                name="country"
                type="text"
                label="Country"
                disabled
                value={data.country}
                onChange={(e) => handleChange('country', e)}
              />
            </Grid>
            <Grid container justify="flex-end" style={{ margin: '10px 0' }}>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !(
                    data.name &&
                    data.phone &&
                    data.line1 &&
                    data.city &&
                    data.province &&
                    data.country &&
                    data.postalCode &&
                    !error.phone &&
                    !error.postalCode &&
                    !formIsDirty
                  )
                }
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </div>
  );
};

export default ClientForm;
