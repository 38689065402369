import { Grid, Typography } from '@material-ui/core';
import React from 'react';

interface Props {
  title: string;
  detail: string;
}

const DetailsCard = (props: Props) => {
  const { title, detail } = props;
  return (
    <Grid
      container
      item
      alignContent="center"
      direction="column"
      justify="space-between"
      style={{ padding: '15px' }}
      md={3}
      sm={6}
    >
      <Typography
        align="center"
        variant="h5"
        style={{ color: '#0083ff', fontWeight: 'bold' }}
      >
        {title}
      </Typography>
      <Typography variant="caption" align="center" style={{ color: 'white' }}>
        {detail}
      </Typography>
    </Grid>
  );
};

export default DetailsCard;
