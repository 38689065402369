import {
    GET_ADMIN_HISTORY_REQUEST,
    GET_ADMIN_HISTORY_SUCCESS,
    GET_ADMIN_HISTORY_FAILURE,
  } from '../actions/getAdminHistoryAction';
  
  function getAdminHistory(
    state = {
      isFetching: false,
      history: null,
      fetched: false,
    },
    action: any
  ) {
    switch (action.type) {
      case GET_ADMIN_HISTORY_REQUEST:
        return Object.assign({}, state, {
          isFetching: true,
          history: null,
        });
      case GET_ADMIN_HISTORY_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false,
          history: action?.response?.data,
          fetched: true,
        });
      case GET_ADMIN_HISTORY_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
        });
      default:
        return state;
    }
  }
  
  export default getAdminHistory;
  