import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import React from 'react';

import { ClientData, OrderData } from '../../model';

interface Props {
  clientData: ClientData;
  handleOpenClient: (data: ClientData) => void;
  handleOpenOrder: (data: OrderData) => void;
}

const ClientCardAdmin = (props: Props) => {
  return (
    <Card
      variant="outlined"
      color="primary"
      style={{ height: '80px', margin: '15px 0' }}
    >
      <CardContent>
        <Grid container alignItems="center">
          <Grid container item xs={8}>
            <Grid item xs={6}>
              <Typography>
                <b>Name: </b>
                {props.clientData.name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <b>City: </b>
                {props.clientData.city}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <b>Postal Code: </b>
                {props.clientData.postalCode}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <b>Phone: </b>
                {props.clientData.phone}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item direction="row" justify="flex-end" xs={4}>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="primary"
                style={{ marginRight: '10px' }}
                onClick={() => props.handleOpenClient(props.clientData)}
              >
                Edit Client
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => props.handleOpenOrder(props.clientData)}
              >
                Create Order
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ClientCardAdmin;
