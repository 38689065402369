import { CALL_API } from '../middleware/api';

export const CREATE_ADMIN_ORDERS_REQUEST = 'CREATE_ADMIN_ORDERS_REQUEST';
export const CREATE_ADMIN_ORDERS_SUCCESS = 'CREATE_ADMIN_ORDERS_SUCCESS';
export const CREATE_ADMIN_ORDERS_FAILURE = 'CREATE_ADMIN_ORDERS_FAILURE';

export function createAdminOrders(data: any) {
  return {
    [CALL_API]: {
      endpoint: 'admin/createbulkorder',
      authenticated: true,
      method: 'POST',
      data,
      types: [
        CREATE_ADMIN_ORDERS_REQUEST,
        CREATE_ADMIN_ORDERS_SUCCESS,
        CREATE_ADMIN_ORDERS_FAILURE,
      ],
    },
  };
}
