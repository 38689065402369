import React from 'react';
import { Button } from '@material-ui/core';
import { read, utils } from 'xlsx/xlsx';
import { DateTimePicker } from './DateTimePicker';
import { OrdersModal } from '../Order/OrdersModal';
import { showSnackbar } from '../redux/actions/uiActions';
import { useDispatch } from 'react-redux';
import { ExcelFileUploadErrorModal } from './ExcelFIleUploadErrorModal';
import { validateOrderFieldsFromExcel } from './util';
interface Props {
  profile: any;
  isAdmin: boolean;
}

const UploadOrdersFromExcel = (props: Props) => {
  const [headers, setHeaders] = React.useState<Array<any>>([]);
  const [orders, setOrders] = React.useState<Array<any>>([]);
  const [errorMap, setErrorMap] = React.useState<Array<any>>([]);
  const [dateTimeModalOpen, setDateTimeModalOpen] = React.useState(false);
  const [ordersModalOpen, setOrdersModalOpen] = React.useState(false);
  const [errorModalOpen, setErrorModalOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState('');
  const [selectedTime, setSelectedTime] = React.useState('');
  const dispatch = useDispatch();
  const handleDateTimeModalClose = () => {
    setDateTimeModalOpen(false);
  };
  const handleOrdersModalClose = () => {
    setOrdersModalOpen(false);
  };
  const handleDateTimeSubmit = (date: string, time: string) => {
    setSelectedDate(date);
    setSelectedTime(time);
    setDateTimeModalOpen(false);
    setOrdersModalOpen(true);
  };
  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
  }
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e?.target?.result;
        const workbook = data && read(data, { type: 'Array' });
        const sheetName = workbook && workbook.SheetNames[0];
        const worksheet = sheetName && workbook.Sheets[sheetName];
        const range = utils.decode_range(worksheet['!ref']);
        range.s.r = 1;
        worksheet['!ref'] = utils.encode_range(range);
        const json =
          worksheet &&
          utils.sheet_to_json(worksheet, { header: 1, blankrows: false });
        const headers = json[0];
        headers.shift();
        json.shift();
        const errorMap: Array<any> = [];
        const ordersFromExcel = json.filter((item, index) => {
          item.shift();
          const orderError = validateOrderFieldsFromExcel(item, index);
          if (orderError) {
            errorMap.push(orderError);
            setErrorMap(errorMap);
          }
          return item.length >= 1;
        });

        if (!!errorMap.length) {
          setErrorModalOpen(true);
          return;
        }

        if (!ordersFromExcel?.length) {
          dispatch(showSnackbar('Incorrect File Format', 'error'));
          return;
        }

        setHeaders(headers);
        setOrders(ordersFromExcel);
        setDateTimeModalOpen(true);
      };
      e?.target?.files[0] && reader.readAsArrayBuffer(e.target.files[0]);
      e.target.value = null;
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        component="label"
      >
        Upload File
        <input hidden type="file" onChange={readUploadFile} />
      </Button>
      {
        errorModalOpen && (
          <ExcelFileUploadErrorModal
            open={errorModalOpen}
            errors={errorMap}
            handleClose={handleErrorModalClose}
          ></ExcelFileUploadErrorModal>
        )
      }
      {dateTimeModalOpen && (
        <DateTimePicker
          handleClose={handleDateTimeModalClose}
          handleSubmit={handleDateTimeSubmit}
          open={dateTimeModalOpen}
          isAdmin={props.isAdmin}
          profile={props.profile}
        ></DateTimePicker>
      )}
      {ordersModalOpen && (
        <OrdersModal
          headers={headers}
          orders={orders}
          handleClose={handleOrdersModalClose}
          profile={props.profile}
          open={ordersModalOpen}
          isAdmin={props.isAdmin}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
        ></OrdersModal>
      )}
    </>
  );
};

export default UploadOrdersFromExcel;
