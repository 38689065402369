import { OrderData } from '../../model';
import { CALL_API } from '../middleware/api';

export const DELETE_ORDER_ADMIN_REQUEST = 'DELETE_ORDER_ADMIN_REQUEST';
export const DELETE_ORDER_ADMIN_SUCCESS = 'DELETE_ORDER_ADMIN_SUCCESS';
export const DELETE_ORDER_ADMIN_FAILURE = 'DELETE_ORDER_ADMIN_FAILURE';

export function deleteOrderAdmin(data: OrderData | null) {
  return {
    [CALL_API]: {
      endpoint: `admin/deleteOrder/${data?.id}?email=${data?.email}`,
      authenticated: true,
      method: 'DELETE',
      types: [DELETE_ORDER_ADMIN_REQUEST, DELETE_ORDER_ADMIN_SUCCESS, DELETE_ORDER_ADMIN_FAILURE],
    },
  };
}
