import React, { useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import DateRangePicker from '../common/DateRangePicker';
import { OrderType } from '../constants';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, makeStyles } from '@material-ui/core';
import { formatISO, isFirstDayOfMonth, isLastDayOfMonth } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { GET_ADMIN_ANALYTICS_SUCCESS, getAdminAnalytics } from '../redux/actions/getAdminAnalyticsAction';
import Spinner from '../common/Spinner';
import { getAdminCustomers } from '../redux/actions/getAdminCustomers';
import { Alert } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: '8px 0',
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    graphContainer: {
        display: 'flex',
        flex: 1
    },
    datePicker: {
        justifyContent: 'flex-start'
    },
    sideBar: {
        display: 'flex',
        flexDirection: 'column',
        padding: '12px',
        borderRight: '1px',
        background: 'rgba(0, 0, 0, 0.04)',
        boxShadow: '20px 0 50px 0 grey'
    },
    center: {
        margin: 'auto 0'
    },
    noChart: {
        display: 'flex',
        justifyContent: 'center'
    },
    alert: {
        marginBottom: '8px',
    }
}));

export const Analytics = () => {
    const [minDateFilter, setMinDateFilter] = useState<MaterialUiPickersDate>(null);
    const [maxDateFilter, setMaxDateFilter] = useState<MaterialUiPickersDate>(null);
    const [selectedPharmacies, setSelectedPharmacies] = React.useState<any>([]);
    const [viewType, setViewType] = React.useState<string>('daily');
    const [graphType, setGraphType] = React.useState<string>('bar');
    const [submittedGraphType, setSubmittedGraphType] = React.useState<any>(null);
    const [submittedViewType, setSubmittedViewType] = React.useState<any>(null);
    const [submittedPharmacies, setSubmittedPharmacies] = React.useState<any>(null);
    const fetching = useSelector((state: any) => state?.getAdminAnalytics?.isFetching);
    const analyticsData = useSelector((state: any) => state?.getAdminAnalytics?.analyticsData);
    const customers = useSelector((state: any) => state?.getAdminCustomers?.customers);
    const dispatch = useDispatch();
    const classes = useStyles();
    let dataset: any = [];

    !customers && dispatch(getAdminCustomers());

    const pharmacyListObject = {};
    const pharmacyList = customers && customers.map(customer => {
        pharmacyListObject[customer.email] = customer.pharmacyName
        return {
            email: customer.email,
            name: customer.pharmacyName
        }
    });
    const isFirstDay = !!minDateFilter && isFirstDayOfMonth(minDateFilter);
    const isLastDay = !!maxDateFilter && isLastDayOfMonth(maxDateFilter);

    const handlePharmacySelection = (event) => {
        setSelectedPharmacies(event.target.value);
    };
  
    const handleViewTypeChange = (event) => {
        setViewType(event.target.value);
    };

    const handleGraphTypeChange = (event) => {
        setGraphType(event.target.value);
    };

    const handleSubmit = async () => {
        const fromDate = minDateFilter && formatISO(minDateFilter, { representation: 'date' });
        const toDate = maxDateFilter && formatISO(maxDateFilter, { representation: 'date' });

        try {
            const result: any = await dispatch(getAdminAnalytics({fromDate, toDate, pharmacies: selectedPharmacies.join(), viewType}));
            if (result?.type === GET_ADMIN_ANALYTICS_SUCCESS) {
                setSubmittedGraphType(graphType);
                setSubmittedViewType(viewType);
                setSubmittedPharmacies(selectedPharmacies);
            }
        } catch (e) {
            console.log(e);
        }
    }

    if (fetching) {
        return <Spinner />;
    }

    const getXAxisData = () => {
        const xAxisData: any = [];
        if (analyticsData && analyticsData.xAxis) {
            xAxisData.push({
                id: 'barCategories',
                dataKey: submittedViewType === 'monthly' ? 'month' : 'date',
                scaleType: 'band',
                tickLabelStyle: {
                    angle: 75,
                    textAnchor: 'start',
                    fontSize: 12,
                },
                valueFormatter: formatXAxis
            });
        }
        return xAxisData;
    }

    const formatXAxis = (xAxis: string) => {
        return !!xAxis && submittedViewType === 'monthly' ? xAxis.replace('-', ' ') : xAxis;
    }

    const getAverageOrderCount = (pharmacy: string) => {
        const xAxistLength = dataset ? dataset.filter(data => data[pharmacy] > 0).length : 1;
        return Math.round((analyticsData.totalOrderCount[pharmacy]/xAxistLength) * 100)/100
    }

    const getGraphData = () => {
        const graphData: any = [];
        submittedPharmacies && submittedPharmacies.forEach(pharmacy => {
            graphData.push({
                label: `${pharmacyListObject[pharmacy]}(${analyticsData.totalOrderCount[pharmacy]} - avg: ${getAverageOrderCount(pharmacy)})`,
                dataKey: pharmacy,
                showMark: false
            });
        });
        return graphData;
    }

    const getDataset = () => {
        if (analyticsData?.viewType === 'daily') {
          analyticsData?.xAxis?.map((axis: string) => {
            const data: any = {};
            analyticsData?.orders?.map((order: any) => {
              data['date'] = axis;
              data[order.email] = order.orderCounts[axis] || 0;
            });
            !!Object.keys(data).find((key: any) => data[key] > 0) && dataset.push(data);
          });
        }

        if (analyticsData?.viewType === 'monthly') {
          analyticsData?.xAxis?.map((axis: string) => {
            const data: any = {};
            analyticsData?.orders.map((order: any) => {
              data['month'] = axis;
              data[order.email] = order.orderCounts[axis] || 0;
            });
            !!Object.keys(data).find((key: any) => data[key] > 0) && dataset.push(data);
          });
        }

        return dataset;
    }

    const chartSetting = {
        maxWidth: 1440,
        height: 550,
        sx: {width: '90% !important'}
    };
    
    return (
        <Grid container className={classes.graphContainer}>
            <Grid item xs={9} className={classes.center}>
                <>
                    {
                        !analyticsData && 
                        <div className={classes.noChart}>
                            Apply the Filters to the right to see chart
                        </div>
                    }
                    { !!analyticsData && submittedGraphType === 'bar' &&
                        <div>
                            <BarChart
                                dataset={getDataset()}
                                xAxis={getXAxisData()}
                                series={getGraphData()}
                                {...chartSetting}
                            />
                        </div>
                    }
                    { !!analyticsData && submittedGraphType === 'line' &&
                        <div>
                            <LineChart
                                dataset={getDataset()}
                                xAxis={getXAxisData()}
                                series={getGraphData()}
                                {...chartSetting}
                            />
                        </div>
                    }
                </>
            </Grid>
            <Grid item xs={3} className={classes.sideBar}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="pharmacy-selection">Select Pharmacies</InputLabel>
                    <Select
                        labelId="pharmacy-selection"
                        id="pharmacy-selection-checkbox"
                        label="Select Pharmacies"
                        multiple
                        value={selectedPharmacies}
                        onChange={(event: any) => handlePharmacySelection(event)}
                        renderValue={(selected: any) => 
                            !selected.length 
                                ? ''
                                : selected.length > 1 
                                    ? `${pharmacyListObject[selected[0]]}, and ${selected.length - 1} other(s)`
                                    : pharmacyListObject[selected[0]]
                        }
                    >
                    {pharmacyList && pharmacyList.map((pharmacy) => (
                        <MenuItem key={pharmacy.name} value={pharmacy.email}>
                            <Checkbox checked={!!selectedPharmacies.find(selectedPharmacy => selectedPharmacy === pharmacy.email)} />
                            <ListItemText primary={pharmacy.name} />
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                <DateRangePicker
                    className={classes.datePicker}
                    minDate={minDateFilter}
                    maxDate={maxDateFilter}
                    setMinDate={setMinDateFilter}
                    setMaxDate={setMaxDateFilter}
                    dateRangeType={OrderType.CURRENT}
                    showOrderCount={false}
                />
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="viewType-selection">View</InputLabel>
                    <Select
                        labelId="viewType-selection"
                        id="viewType-selection-checkbox"
                        label="View"
                        value={viewType}
                        onChange={handleViewTypeChange}
                    >
                        <MenuItem key='daily' value='daily'>Daily</MenuItem>
                        <MenuItem key='monthly' value='monthly'>Monthly</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="graphType-selection">Chart Type</InputLabel>
                    <Select
                        labelId="graphType-selection"
                        id="graphType-selection-checkbox"
                        label="Chart Type"
                        value={graphType}
                        onChange={handleGraphTypeChange}
                    >
                        <MenuItem key='bar' value='bar'>Bar</MenuItem>
                        <MenuItem key='line' value='line'>Line</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    className={classes.center}
                    variant="contained"
                    color="primary"
                    disabled={!(selectedPharmacies.length && minDateFilter && maxDateFilter)}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
                {!!minDateFilter && !isFirstDay &&
                    <Alert className={classes.alert} variant="outlined" severity="warning">Selected Start date is not the first day of the month</Alert>
                }
                {!!maxDateFilter && !isLastDay &&
                    <Alert className={classes.alert} variant="outlined" severity="warning">Selected End date is not the last day of the month</Alert>
                }
            </Grid>
        </Grid>
    )
}
