import {
  DELETE_PHARMACY_ADMIN_REQUEST,
  DELETE_PHARMACY_ADMIN_SUCCESS,
  DELETE_PHARMACY_ADMIN_FAILURE,
} from '../actions/deletePharmacyAdmin';

function deletePharmacyAdmin(
  state = {
    isFetching: false,
  },
  action: any
) {
  switch (action.type) {
    case DELETE_PHARMACY_ADMIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case DELETE_PHARMACY_ADMIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
      });
    case DELETE_PHARMACY_ADMIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
}

export default deletePharmacyAdmin;
