import { ProfileData } from '../../model';
import {
  GET_ADMIN_CUSTOMERS_FAILURE,
  GET_ADMIN_CUSTOMERS_SUCCESS,
  GET_ADMIN_CUSTOMERS_REQUEST,
} from '../actions/getAdminCustomers';

function getAdminCustomers(
  state = {
    isFetching: false,
    customersFetched: false,
    customers: null,
  },
  action: any
) {
  switch (action.type) {
    case GET_ADMIN_CUSTOMERS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        customers: null,
        customersFetched: false,
      });
    case GET_ADMIN_CUSTOMERS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        customers: action.response?.data?.Items as ProfileData[],
        customersFetched: true,
      });
    case GET_ADMIN_CUSTOMERS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
}

export default getAdminCustomers;
