import React from 'react';
import { AppBar, Grid, IconButton, Toolbar } from '@material-ui/core';
import logo from './logo.png';
import styled from 'styled-components';

import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from './redux/actions/authAction';
import { useHistory } from 'react-router-dom';

interface Props {
  isAuthenticated: boolean;
  isAdmin: boolean;
}

export const NavBar = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [location, setLocation] = React.useState('/');
  const open = Boolean(anchorEl);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    dispatch(logoutUser());
  };

  const handleProfileClick = () => {
    setAnchorEl(null);
    history.push('/customer-info');
  };

  const handleDashboardClick = () => {
    setAnchorEl(null);
    history.push('/dashboard');
  };

  const handleAnalyticsClick = () => {
    setAnchorEl(null);
    history.push('/analytics');
  }

  const { isAuthenticated } = props;

  history.listen((location) => setLocation(location.pathname));

  return (
    <StyledNav position="static">
      <Toolbar>
        <Grid
          container
          justify="space-between"
          direction="row"
          alignItems="center"
          style={{ width: '100%', display: 'flex' }}
        >
          <Grid item xs={3}>
            <Link to="/">
              <img height="40px" width="120px" alt="logo" src={logo} />
            </Link>
          </Grid>
          <Grid item xs={9} container justify="flex-end" alignItems="center">
            {location === '/' && (
              <>
                <StyledAnchor href="#aboutus">About Us</StyledAnchor>
                <StyledAnchor href="#contactus">Contact Us</StyledAnchor>
              </>
            )}
            {isAuthenticated ? (
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="default"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleDashboardClick}>Dashboard</MenuItem>
                  {props.isAdmin && (
                    <MenuItem onClick={handleAnalyticsClick}>Analytics</MenuItem>
                  )}
                  {!props.isAdmin && (
                    <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
                  )}
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            ) : (
              <StyledLink to="/signin">Login</StyledLink>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </StyledNav>
  );
};

const StyledNav = styled(AppBar)`
  top: 0;
  margin: 0;
  background: #f2f2f2;
`;

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  padding-left: 12px;
`;

const StyledAnchor = styled.a`
  color: black;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  padding: 0 12px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
