import {
  GET_ADMIN_ORDER_FAILURE,
  GET_ADMIN_ORDER_SUCCESS,
  GET_ADMIN_ORDER_REQUEST,
} from '../actions/getAdminOrderInfo';

function getAdminOrderInfo(
  state = {
    isFetching: false,
    fetched: false,
    orders: null,
    profile: null,
  },
  action: any
) {
  switch (action.type) {
    case GET_ADMIN_ORDER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        fetched: false,
        orders: null,
        profile: null,
      });
    case GET_ADMIN_ORDER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        fetched: true,
        currentOrders: action?.response?.data?.currentOrders,
        futureOrders: action?.response?.data?.futureOrders,
        previousOrders: action?.response?.data?.previousOrders,
        clients: action?.response?.data?.clients,
        profile:
          action.response?.data?.profile && action.response?.data?.profile[0],
        customersFetched: true,
      });
    case GET_ADMIN_ORDER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        fetched: true,
      });
    default:
      return state;
  }
}

export default getAdminOrderInfo;
