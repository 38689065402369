import { combineReducers } from 'redux';
import auth from './authReducer';
import editProfile from './editProfileReducer';
import createOrders from './createOrderReducer';
import getProfile from './getProfileReducer';
import getOrders from './getOrdersReducer';
import getClients from './getClientsReducer';
import getAdminCustomers from './getAdminCustomersReducer';
import getAdminOrderInfo from './getAdminOrderInfo';
import deleteOrder from './deleteOrderReducer';
import adminEditProfile from './adminEditProfileReducer';
import deletePharmacyAdmin from './deletePharmacyAdmin';
import uiReducer from './uiReducers';
import createAdminOrdersReducer from './createAdminOrdersReducer';
import getAdminHistory from './getAdminHistoryReducer';
import getAdminAnalytics from './getAdminAnalyticsReducer';

const reducers = combineReducers({
  auth,
  editProfile,
  createOrders,
  getProfile,
  getOrders,
  getClients,
  getAdminCustomers,
  getAdminOrderInfo,
  deleteOrder,
  uiReducer,
  adminEditProfile,
  createAdminOrdersReducer,
  deletePharmacyAdmin,
  getAdminHistory,
  getAdminAnalytics
});

export default reducers;
