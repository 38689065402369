import { CALL_API } from '../middleware/api';

import { ClientData } from '../../model';

export const CREATE_CLIENT_REQUEST = 'CREATE_CLIENT_REQUEST';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE';

export function createClient(data: ClientData) {
  return {
    [CALL_API]: {
      endpoint: 'createclient',
      authenticated: true,
      method: 'POST',
      data,
      types: [
        CREATE_CLIENT_REQUEST,
        CREATE_CLIENT_SUCCESS,
        CREATE_CLIENT_FAILURE,
      ],
    },
  };
}
