import { CALL_API } from '../middleware/api';

import { ProfileData } from '../../model';

export const ADMIN_EDIT_PROFILE_REQUEST = 'ADMIN_EDIT_PROFILE_REQUEST';
export const ADMIN_EDIT_PROFILE_SUCCESS = 'ADMIN_EDIT_PROFILE_SUCCESS';
export const ADMIN_EDIT_PROFILE_FAILURE = 'ADMIN_EDIT_PROFILE_FAILURE';

export function adminEditProfile(data: ProfileData) {
  return {
    [CALL_API]: {
      endpoint: 'admin/editprofile',
      authenticated: true,
      method: 'POST',
      data,
      types: [
        ADMIN_EDIT_PROFILE_REQUEST,
        ADMIN_EDIT_PROFILE_SUCCESS,
        ADMIN_EDIT_PROFILE_FAILURE,
      ],
    },
  };
}
