import {
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
} from '../actions/deleteOrderAction';

function deleteOrder(
  state = {
    isFetching: false,
  },
  action: any
) {
  switch (action.type) {
    case DELETE_ORDER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case DELETE_ORDER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
      });
    case DELETE_ORDER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
}

export default deleteOrder;
