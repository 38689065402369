import { CALL_API } from '../middleware/api';

export const GET_ADMIN_HISTORY_REQUEST = 'GET_ADMIN_HISTORY_REQUEST';
export const GET_ADMIN_HISTORY_SUCCESS = 'GET_ADMIN_HISTORY_SUCCESS';
export const GET_ADMIN_HISTORY_FAILURE = 'GET_ADMIN_HISTORY_FAILURE';

export function getAdminHistory(email: string) {
  return {
    [CALL_API]: {
      endpoint: `admin/getHistory/${email}`,
      authenticated: true,
      method: 'GET',
      types: [GET_ADMIN_HISTORY_REQUEST, GET_ADMIN_HISTORY_SUCCESS, GET_ADMIN_HISTORY_FAILURE],
    },
  };
}
