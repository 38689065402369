import { checkAuthenticated } from '../../helper/checkAuth';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_REQUEST,
  LOGIN_RESET_PASSWORD,
} from '../actions/authAction';

function auth(
  state = {
    isFetching: false,
    isAuthenticated: checkAuthenticated(),
  },
  action: any
) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        user: action.creds,
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        resetPassword: false,
        errorMessage: '',
      });
    case LOGIN_RESET_PASSWORD:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        resetPassword: true,
        errorMessage: '',
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message,
      });
    case LOGOUT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: true,
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
      });
    default:
      return state;
  }
}

export default auth;
