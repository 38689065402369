import { CALL_API } from '../middleware/api';

export const GET_ADMIN_ORDER_REQUEST = 'GET_ADMIN_ORDER_REQUEST';
export const GET_ADMIN_ORDER_SUCCESS = 'GET_ADMIN_ORDER_SUCCESS';
export const GET_ADMIN_ORDER_FAILURE = 'GET_ADMIN_ORDER_FAILURE';

export function getAdminOrderInfo(email: string) {
  return {
    [CALL_API]: {
      endpoint: `admin/customerinfo/${email}`,
      authenticated: true,
      method: 'GET',
      types: [
        GET_ADMIN_ORDER_REQUEST,
        GET_ADMIN_ORDER_SUCCESS,
        GET_ADMIN_ORDER_FAILURE,
      ],
    },
  };
}
