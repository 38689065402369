import React from 'react';
import ReactExport from 'react-export-excel';
import { Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class Download extends React.Component<any> {
  render() {
    return (
      <ExcelFile
        filename={
          this.props?.name
            ? `${this.props?.name}-${new Date().toDateString()}`
            : 'Download'
        }
        element={
          <Button
            disabled={!this.props.data.length}
            variant="contained"
            color="primary"
            size="small"
            style={{ marginBottom: '10px' }}
            startIcon={<GetAppIcon />}
          >
            Download Data
          </Button>
        }
      >
        <ExcelSheet data={this.props?.data} name="Employees">
          <ExcelColumn label="Recipient_Name" value="name" />
          <ExcelColumn label="Recipient_Phone" value="phone" />
          <ExcelColumn label="Notification" value="notification" />
          <ExcelColumn label="Recipient_Notes" value="notes" />
          <ExcelColumn label="Address_Line1" value="line1" />
          <ExcelColumn label="Address_Line2" value="pharmacyName" />
          <ExcelColumn label="City/Town" value="city" />
          <ExcelColumn label="Postal_Code" value="postalCode" />
          <ExcelColumn label="State/Province" value="province" />
          <ExcelColumn label="Country" value="country" />
          <ExcelColumn label="Latitude" value="lat" />
          <ExcelColumn label="Longitude" value="long" />
          <ExcelColumn label="Task_Details" value="details" />
          <ExcelColumn label="Pickup" value="pickup" />
          <ExcelColumn label="completeAfter" value="completeAfter" />
          <ExcelColumn label="completeBefore" value="completeBefore" />
          <ExcelColumn label="Organization" value="org" />
          <ExcelColumn label="Driver" value="driver" />
        </ExcelSheet>
      </ExcelFile>
    );
  }
}
