import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
} from '../actions/getProfileAction';

function getProfile(
  state = {
    isFetching: false,
    profile: null,
  },
  action: any
) {
  switch (action.type) {
    case GET_PROFILE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        profile: null,
      });
    case GET_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        profile: action.response?.data?.Items[0],
      });
    case GET_PROFILE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
}

export default getProfile;
