import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';

import PrivateRoute from './common/PrivateRoute';
import { SignInForm } from './SignInForm/SignInForm';
import { NavBar } from './NavBar';
import { CustomerInfo } from './CustomerInfo/CustomerInfo';
import { Dashboard } from './Dashboard';
import { getProfile } from './redux/actions/getProfileAction';
import Spinner from './common/Spinner';
import Homepage from './Homepage';
import CustomerOrders from './admin/customer/CustomerOrders';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Footer } from './Footer';
import { Analytics } from './analytics/analytics';

function App() {
  const dispatch = useDispatch();
  const authenticated = useSelector((state: any) => state.auth.isAuthenticated);

  useEffect(() => {
    authenticated && dispatch(getProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  const profileCompleted = useSelector(
    (state: any) => state?.getProfile?.profile?.formComplete
  );

  const isAdmin = useSelector(
    (state: any) => state?.getProfile?.profile?.admin
  );

  let profile = useSelector((state: any) => state?.getProfile?.profile);
  useSelector((state: any) => {
    if (state?.editProfile?.profile) {
      profile = state.editProfile.profile;
    }
  });

  const fetching = useSelector(
    (state: any) => state?.getProfile?.isFetching || state?.auth?.isFetching
  );

  const showSnackBar = useSelector(
    (state: any) => state?.uiReducer?.snackbarOpen
  );

  const snackbarType = useSelector(
    (state: any) => state?.uiReducer?.snackbarType
  );

  const message = useSelector(
    (state: any) => state?.uiReducer?.snackbarMessage
  );

  return (
    <>
      <CssBaseline />
      <Snackbar open={showSnackBar} autoHideDuration={3000}>
        <Alert severity={snackbarType}>{message}</Alert>
      </Snackbar>
      <Router>
        <NavBar isAuthenticated={authenticated} isAdmin={isAdmin} />
        <Switch>
          {fetching && <Route path="/" component={Spinner} />}
          {!fetching && !profileCompleted && authenticated && !isAdmin && (
            <Route path="/" component={CustomerInfo} />
          )}
          <Route path="/" exact component={Homepage} />
          <Route
            exact
            path="/signin"
            render={(props) =>
              !authenticated ? (
                <SignInForm />
              ) : (
                <Redirect
                  to={{
                    pathname: '/dashboard',
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
          <PrivateRoute
            path="/dashboard"
            isAuthenticated={authenticated}
            exact
            component={Dashboard}
            data={isAdmin}
          />
          {isAdmin && (
            <PrivateRoute
              isAuthenticated={authenticated}
              path="/admin/:email/customer"
              exact
              component={CustomerOrders}
              data={profile}
            />
          )}
          {!isAdmin && (
            <PrivateRoute
              isAuthenticated={authenticated}
              path="/customer-info"
              exact
              component={CustomerInfo}
              data={profile}
            />
          )}
          {isAdmin && (
            <PrivateRoute
              isAuthenticated={authenticated}
              path="/analytics"
              exact
              component={Analytics}
            />
          )}
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
