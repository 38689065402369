import {
    GET_ADMIN_ANALYTICS_REQUEST,
    GET_ADMIN_ANALYTICS_SUCCESS,
    GET_ADMIN_ANALYTICS_FAILURE,
  } from '../actions/getAdminAnalyticsAction';
  
  function getAdminAnalytics(
    state = {
      isFetching: false,
      analyticsData: null,
      fetched: false,
    },
    action: any
  ) {
    switch (action.type) {
      case GET_ADMIN_ANALYTICS_REQUEST:
        return Object.assign({}, state, {
          isFetching: true,
          analyticsData: null,
        });
      case GET_ADMIN_ANALYTICS_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false,
          analyticsData: action?.response?.data,
          fetched: true,
        });
      case GET_ADMIN_ANALYTICS_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
        });
      default:
        return state;
    }
  }
  
  export default getAdminAnalytics;
  