import React from 'react';
import { Button, Dialog, Grid } from '@material-ui/core';
import { CellParams, DataGrid } from '@material-ui/data-grid';
import { convertFrom24To12Format } from '../helper/date';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAdminOrders,
  CREATE_ADMIN_ORDERS_SUCCESS,
} from '../redux/actions/createAdminOrdersAction';
import { omit } from 'lodash';
import { showSnackbar } from '../redux/actions/uiActions';
import { getAdminOrderInfo } from '../redux/actions/getAdminOrderInfo';
import { getOrders } from '../redux/actions/getOrdersAction';
import { getClients } from '../redux/actions/getClientsActions';
import { addDashes, formatPostalCode } from '../common/util';
interface Props {
  orders: Array<any>;
  headers: Array<any> | null;
  profile: any;
  isAdmin: boolean;
  open: boolean;
  selectedDate: string;
  selectedTime: string;
  handleClose: () => void;
}

export const OrdersModal = (props: Props) => {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectionMade, setSelectionMade] = React.useState(false);
  const clients = useSelector((state: any) =>
    props.isAdmin ? state.getAdminOrderInfo?.clients : state.getClients?.clients
  );

  const columns: any = [
    { field: 'id', hide: true },
    { field: 'lastName', headerName: 'Last Name', flex: 1 },
    { field: 'firstName', headerName: 'First Name', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'date', headerName: 'Delivery Date', flex: 1 },
    {
      field: 'deliveryTime',
      headerName: 'Delivery Time',
      flex: 1,
      renderCell: (params: CellParams) =>
        convertFrom24To12Format(params?.row?.deliveryTime),
    },
    { field: 'line1', headerName: 'Address', flex: 1 },
    { field: 'city', headerName: 'City', flex: 1 },
    { field: 'province', headerName: 'Province', flex: 1 },
    { field: 'country', headerName: 'Country', flex: 1 },
    { field: 'postalCode', headerName: 'Postal Code', flex: 1 },
    { field: 'notes', headerName: 'Notes', flex: 1 },
  ];

  const rows: any = props.orders
    ? props.orders.map((item, index) => {
        return {
          id: index + 1,
          lastName: item[0],
          firstName: item[1],
          name: `${item[0]} ${item[1]}`,
          line1: item[2],
          city: item[3],
          postalCode: item[4],
          phone: item[5],
          province: 'Ontario',
          country: 'Canada',
          date: props.selectedDate,
          deliveryTime: props.selectedTime,
          notes: item[6],
          email: props.profile.email,
        };
      })
    : [{}];

  const handleOrdersSelection = (e) => {
    setSelectedRows(e);
    if (e?.rowIds?.length === 0) {
      setSelectionMade(false);
    } else {
      setSelectionMade(true);
    }
  };

  const submitOrders = async () => {
    let orders;
    const selectedRowIds = (selectedRows as any).rowIds;
    if (selectedRowIds && selectedRowIds.length) {
      orders = rows.filter((_, index) => {
        const y = (index + 1).toString();
        return selectedRowIds.indexOf(y) !== -1;
      });
    } else {
      orders = rows;
    }

    const ordersMap = orders.map((order) => {
      const orderFormatted = {
        ...order,
        phone: addDashes(order.phone),
        postalCode: formatPostalCode(order.postalCode),
      };
      return omit(orderFormatted, 'id');
    });

    let phoneSet = new Set();
    clients?.forEach((client) => {
      phoneSet.add(client.phone);
    });

    const newClients = ordersMap
      .map((order) => {
        if (phoneSet.has(order.phone)) {
          return undefined;
        }

        return {
          city: order.city,
          country: order.country,
          email: order.email,
          line1: order.line1,
          name: order.name,
          phone: order.phone,
          postalCode: order.postalCode,
          province: order.province,
        };
      })
      .filter((order) => order !== undefined);

    try {
      let result: any = null;
      result = await dispatch(
        createAdminOrders({
          clients: newClients,
          orders: ordersMap,
        })
      );
      if (result?.type === CREATE_ADMIN_ORDERS_SUCCESS) {
        props.handleClose();
        if (props.isAdmin) {
          await dispatch(getAdminOrderInfo(props.profile.email));
        } else {
          await dispatch(getClients());
          await dispatch(getOrders());
        }
        await dispatch(showSnackbar('Action completed', 'success'));
      } else {
        props.handleClose();
        await dispatch(showSnackbar('Action failed', 'error'));
      }
    } catch (e: any) {}
  };

  return (
    <Dialog
      maxWidth={'xl'}
      color="white"
      open={props.open}
      onClose={props.handleClose}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ height: '80vh', width: '90vw', paddingTop: '16px' }}
      >
        <Grid item style={{ width: '90vw', height: '70vh' }}>
          <DataGrid
            autoHeight={true}
            disableColumnMenu
            rowHeight={60}
            density="standard"
            rows={rows}
            columns={columns}
            pageSize={20}
            checkboxSelection
            onSelectionChange={handleOrdersSelection}
          />
        </Grid>
        <Grid 
          item
          style={{position: 'fixed', bottom: '60px'}}
        >
          <Button
            disabled={!selectionMade}
            onClick={submitOrders}
            color="primary"
            size="small"
            variant="contained"
            autoFocus
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
