import {
  ADMIN_EDIT_PROFILE_REQUEST,
  ADMIN_EDIT_PROFILE_SUCCESS,
  ADMIN_EDIT_PROFILE_FAILURE,
} from '../actions/adminEditProfileAction';

function adminEditProfile(
  state = {
    isFetching: false,
  },
  action: any
) {
  switch (action.type) {
    case ADMIN_EDIT_PROFILE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case ADMIN_EDIT_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
      });
    case ADMIN_EDIT_PROFILE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      });
    default:
      return state;
  }
}

export default adminEditProfile;
